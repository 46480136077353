import Image from 'next/image';

import IconColor from '@/public/assets/platform/on-call/icon.svg';
import { Icon, ImageOne, ImageTwo, Line, WrapperImage } from './styles';

const ImageOnCall = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="533"
          fill="none"
          viewBox="0 0 261 533"
        >
          <rect
            width="258"
            height="530"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="261"
              x2="-164.521"
              y1="533"
              y2="293.062"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/on-call/image-app.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="204"
          height="129"
          fill="none"
          viewBox="0 0 204 129"
        >
          <path stroke="url(#a)" strokeWidth="3" d="M0 2h170c17.673 0 32 14.327 32 32v95" />
          <defs>
            <linearGradient
              id="a"
              x1="0"
              x2="196.7"
              y1="2"
              y2="136.738"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="318"
          height="254"
          fill="none"
          viewBox="0 0 318 254"
        >
          <rect
            width="315"
            height="251"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="318"
              x2="96.032"
              y1="254"
              y2="-65.998"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/on-call/image-person.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
    </WrapperImage>
  );
};

export default ImageOnCall;
