import { UpperText, H2 } from '@/components/core/Title';

const Header = ({ label, title }) => {
  return (
    <>
      <UpperText>{label}</UpperText>
      <H2>{title}</H2>
    </>
  );
};

export default Header;
