import styled from 'styled-components';

import Card from './Card';

const Grid = ({ content }) => {
  if (!content || !content.length) return null;
  return (
    <Wrapper data-testid="grid">
      {content.map((card, index) => (
        <Card
          key={index}
          title={card.titleCard}
          description={card.descriptionCard}
          linkLabel={card.linkLabelCard}
          link={card.linkCard}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;

export default Grid;
