import styled from 'styled-components';

export const LogoWrapper = styled.div`
  position: absolute;
  width: 118px;
  height: 22px;
  top: 42px;
  right: 42px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    top: 42px;
    right: 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: 20px;
    right: 20px;
  }
`;

export default LogoWrapper;
