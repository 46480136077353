import { Container } from '@/lib/flexbox';
import { H2, UpperText } from '@/components/core/Title';
import Wrapper from './Wrapper';
import GraphicElements from './GraphicElements';
import MarkdownRichText from '@/components/shared/MarkdownRichText';

const OurMission = ({ content }) => {
  const { label, title } = content;
  return (
    <Wrapper>
      <Container>
        <UpperText>{label}</UpperText>
        <H2>
          <MarkdownRichText elements={['b']}>{title}</MarkdownRichText>
        </H2>
        <GraphicElements />
      </Container>
    </Wrapper>
  );
};

export default OurMission;
