import styled from 'styled-components';

const Caption = styled.div`
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  bottom: 40px;
  right: 36px;
  font-size: 14px;
  line-height: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    position: static;
    margin-top: 40px;
  }
`;

export default Caption;
