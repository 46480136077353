import styled, { css, keyframes } from 'styled-components';

import { WrapperLogoDefault, Color, NoColor, Label, labelAnimation } from '../../style';

import { timeAnimation } from '../../Platform';

const logoNocolor = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  54% {
    opacity: 0;
    transform: scale(1);
  }
  65% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const logoColor = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  54% {
    opacity: 1;
    transform: scale(1.1);
  }
  65% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 1261px;
  }
  25% {
    opacity: 1;
    stroke-dashoffset: 1261px;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
    opacity: 1;
    stroke-dashoffset: 2522px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 1261px;
  }
`;
const lineIcon = keyframes`
  0% {
		stroke-dashoffset: 298px;
  }
	50% {
		stroke-dashoffset: 298px;
  }
  100% {
		stroke-dashoffset: 0;
  }
`;
const lineTwo = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 1349px;
  }
  10% {
		opacity: 1;
    stroke-dashoffset: 1349px;
  }
	70% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  70.1% {
		opacity: 1;
    stroke-dashoffset: 2698px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 1349px;
  }
`;
const imageAnimation = keyframes`
  0% {
		opacity: 0;
  }
  50% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageTwoAnimation = keyframes`
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;

export const Wrapper = styled.div`
  ${WrapperLogoDefault};
  top: 71%;
  left: 24%;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${Color} {
      opacity: 1;
      transform: scale(1.1);
    }
    ${NoColor} {
      opacity: 0;
      transform: scale(1.1);
    }
    ${Label} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
  }
`;

export const AnimationColor = styled(Color)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoColor} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const AnimationNoColor = styled(NoColor)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoNocolor} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const AnimationLabel = styled(Label)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${labelAnimation} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const WrapperImage = styled.div`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
export const ImageOne = styled.figure`
  position: absolute;
  top: 0;
  left: 40%;
  width: 52.5%;
  padding-bottom: 52.5%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
    opacity: 1;
  }
  svg {
    width: 94%;
    position: absolute;
    top: 2%;
    left: 3%;
    height: 93%;
    z-index: 2;
    stroke-dasharray: 1261px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${line} 2.5s ease-in-out;
      }
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 93%;
      left: 3.5%;
      height: 92%;
      top: 2.7%;
    }
  }
`;
export const ImageTwo = styled.figure`
  position: absolute;
  top: 37%;
  left: 6%;
  width: 67%;
  padding-bottom: 43%;
  z-index: 2;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
    opacity: 1;
  }
  svg {
    width: 96%;
    position: absolute;
    top: 1%;
    left: 1%;
    height: 95.4%;
    z-index: 2;
    stroke-dasharray: 1349px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineTwo} 2s ease-in-out;
      }
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 93%;
      height: 94%;
      left: 2%;
      top: 1.7%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 96%;
      top: 1%;
      left: 1%;
      height: 95.4%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 92%;
      height: 93%;
      left: 3%;
      top: 1.7%;
    }
  }
`;
export const Line = styled.div`
  position: absolute;
  z-index: 0;
  width: 19%;
  top: 12%;
  left: 27%;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke-dasharray: 298px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${lineIcon} 2.5s ease-in-out;
        }
      }
    `};
`;
export const Icon = styled.div`
  position: absolute;
  z-index: 1;
  top: 18%;
  left: 21%;
  width: 12%;
  height: 12%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
    left: 20.5%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
    left: 20%;
  }
`;
