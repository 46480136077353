import Link from 'next/link';
import IconColor from '@/public/assets/platform/bloom/icon.svg';
import { AnimationColor, AnimationLabel, AnimationNoColor, Wrapper } from './styles';

const SvgBloom = ({ active, delay }) => {
  return (
    <Wrapper>
      <Link href="/solutions/bloom">
        <AnimationColor $active={active} $delay={delay}>
          <IconColor />
        </AnimationColor>
        <AnimationNoColor $active={active} $delay={delay}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="89"
            height="89"
            fill="none"
            viewBox="0 0 89 89"
          >
            <rect width="86" height="86" x="1.02" y="1.807" fill="#F7F4F2" rx="43" />
            <rect
              width="86"
              height="86"
              x="1.02"
              y="1.807"
              stroke="#E6DCD4"
              strokeWidth="2"
              rx="43"
            />
            <path
              fill="#E6DCD4"
              fillRule="evenodd"
              d="M63.725 63.742c5.032-6.023 4.284-15.038-1.67-20.129L44.04 65.178 26.025 43.614c-5.955 5.09-6.703 14.105-1.671 20.129 4.972 5.952 13.734 6.761 19.686 1.868 5.952 4.893 14.713 4.084 19.685-1.869Z"
              clipRule="evenodd"
            />
            <path
              fill="#E6DCD4"
              fillRule="evenodd"
              d="M43.349 20.82a13.968 13.968 0 0 1 11.867 5.695 13.887 13.887 0 0 1 2.298 4.939 14.04 14.04 0 0 1 .237 5.442 13.966 13.966 0 0 1-7.902 10.523 13.999 13.999 0 0 1-8.022 1.144 13.983 13.983 0 0 1-7.307-3.507 13.95 13.95 0 0 1-3.68-15.069 13.974 13.974 0 0 1 12.518-9.167h-.01Zm-7.49 17.73a8.893 8.893 0 0 0 3.443 3.87 8.892 8.892 0 0 0 6.748 1.028 8.99 8.99 0 0 0 6.818-9.092 8.927 8.927 0 0 0-1.704-4.898 8.836 8.836 0 0 0-4.137-3.11 8.872 8.872 0 0 0-5.17-.277 8.974 8.974 0 0 0-6.718 7.338 8.926 8.926 0 0 0 .72 5.14Z"
              clipRule="evenodd"
            />
          </svg>
        </AnimationNoColor>
        <AnimationLabel $active={active} $delay={delay}>
          Bloom
        </AnimationLabel>
      </Link>
    </Wrapper>
  );
};

export default SvgBloom;
