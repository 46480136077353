import styled from 'styled-components';

import { Row } from '@/lib/flexbox';

const MobileRow = styled(Row)`
  display: flex;
  & + & {
    margin-top: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 44px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-flow: column-reverse nowrap;
    margin-top: 16px;
    & + & {
      margin-top: 48px !important;
    }
  }
`;

export default MobileRow;
