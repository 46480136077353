import Link from 'next/link';
import IconColor from '@/public/assets/platform/on-call/icon.svg';
import { AnimationColor, AnimationLabel, AnimationNoColor, Wrapper } from './styles';

const SvgOnCall = ({ active }) => {
  return (
    <Wrapper>
      <Link href="/solutions/on-call">
        <AnimationColor $active={active}>
          <IconColor />
        </AnimationColor>
        <AnimationNoColor $active={active}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="88"
            fill="none"
            viewBox="0 0 88 88"
          >
            <rect width="86" height="86" x="1" y="1" fill="#F7F4F2" rx="43" />
            <rect width="86" height="86" x="1" y="1" stroke="#E6DCD4" strokeWidth="2" rx="43" />
            <path
              fill="#E6DCD4"
              d="M68 63.368c-11.126.107-20.102-8.567-20.102-19.257 0-10.69 9.022-19.459 20.102-19.51v38.767ZM20 63.367c11.08-.045 20.102-8.814 20.102-19.51 0-10.689-8.97-19.363-20.102-19.256v38.766Z"
            />
          </svg>
        </AnimationNoColor>
        <AnimationLabel $active={active}>On Call</AnimationLabel>
      </Link>
    </Wrapper>
  );
};

export default SvgOnCall;
