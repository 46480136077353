import { memo, useState, startTransition } from 'react';
import Image from 'next/image';
import { InView } from 'react-intersection-observer';
import { TestimonialsSlider as UITestimonialsSlider } from '@swordhealth/ui-corporate';

import { getImgData } from '@/utils/images';

const TestimonialsSlider = ({ quotes, ...props }) => {
  const [inView, setInview] = useState(false);

  if (!quotes?.length) return null;

  return (
    <InView
      as="div"
      threshold={0.1}
      triggerOnce={true}
      onChange={(visible) =>
        startTransition(() => {
          setInview(visible);
        })
      }
    >
      <UITestimonialsSlider
        {...props}
        imageComponent={({ src, alt }) => (
          <Image src={src} alt={alt || ''} fill sizes="420px" loading={inView ? 'eager' : 'lazy'} />
        )}
        quotes={quotes.map(({ name, videoURL, picture, role, quote }) => {
          const imageData = getImgData(picture);

          return {
            quote,
            author: name,
            label: role,
            image: imageData ? { src: imageData.url, alt: imageData.alt || '' } : null,
            video: videoURL
              ? {
                  src: videoURL,
                  type: 'video/mp4',
                }
              : null,
          };
        })}
      />
    </InView>
  );
};

export default memo(TestimonialsSlider);
