import styled from 'styled-components';

import Solution from './Solution';
import Indicator from './Indicator';

const Wrapper = styled.div`
  position: sticky;
  top: max(90px, 50vh - 255px);
  height: calc(100vh - 100px);
  max-height: 683px;
`;

const StickyPanel = ({ selected, content, isDesktop }) => {
  return (
    <Wrapper>
      {content.map((solution, index) => (
        <Solution
          key={solution.id}
          content={solution}
          selected={selected === index}
          index={index}
          isDesktop={isDesktop}
        />
      ))}
      <Indicator count={content.length} selected={selected} />
    </Wrapper>
  );
};

export default StickyPanel;
