import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div``;

export const labelAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  54% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const WrapperLogoDefault = `
  position: absolute;
  top: 0;
  left: 0;
  width: 12%;
  height: 12%;
  border-radius: 50%;
  background-color: #F7F4F2;
  z-index:1;
`;
export const Logo = `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
  transition: all 120ms ease-in-out;
`;

export const Color = styled.div`
  ${Logo};
  z-index: 1;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.08);
`;
export const NoColor = styled.div`
  ${Logo};
  z-index: 2;
  opacity: 1;
`;
export const Label = styled.div`
  padding: 12px;
  border-radius: 91px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4.57px 9.14px 0px rgba(0, 0, 0, 0.08);
  font-size: 15px;
  line-height: 12.8px;
  font-weight: 500;
  font-family: ${(props) => props.theme.typography.family.body};
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 120ms ease-in-out;
  width: max-content;
  z-index: 3;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 11px;
    line-height: 10px;
    padding: 6px 12px;
    bottom: -28px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 12px;
    line-height: 10px;
    padding: 8px 12px;
    bottom: -38px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    font-size: 10px;
    line-height: 10px;
    padding: 6px 8px;
    bottom: -28px;
  }
`;
