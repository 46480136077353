import styled from 'styled-components';
import { CircleFilled } from '@/components/core/Circle';
import HomepageDnaShape from '@/public/assets/shapes/homepage_dna.svg';
import theme from '@/utils/styles-variables';

const CustomCircleFilled = styled(CircleFilled)`
  position: absolute;
  bottom: -10px;
  left: -18px;
  width: 459px;
  height: 459px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

const CustomHomepageDnaShape = styled(HomepageDnaShape)`
  position: absolute;
  top: -26px;
  right: 4px;
  width: 200px;
  height: 200px;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

const GraphicElementsAlt = () => (
  <>
    <CustomCircleFilled color={theme.colors.neutral.default} />
    <CustomHomepageDnaShape />
  </>
);

export default GraphicElementsAlt;
