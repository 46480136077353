import Image from 'next/image';

import IconColor from '@/public/assets/platform/thrive/icon.svg';
import { Icon, ImageOne, ImageTwo, Line, WrapperImage } from './styles';

const ImageThrive = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="325"
          height="342"
          fill="none"
          viewBox="0 0 325 342"
        >
          <rect
            width="322"
            height="339"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="35.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="325"
              x2="7.202"
              y1="342"
              y2="-5.754"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/thrive/thrive-image-1.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="188"
          fill="none"
          viewBox="0 0 128 188"
        >
          <path stroke="url(#a)" strokeWidth="3" d="M128 2H34C16.327 2 2 16.327 2 34v154" />
          <defs>
            <linearGradient
              id="a"
              x1="2"
              x2="168.124"
              y1="2"
              y2="50.465"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="409"
          height="289"
          fill="none"
          viewBox="0 0 409 289"
        >
          <rect
            width="406"
            height="286"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="20.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="409"
              x2="168.602"
              y1="289"
              y2="-102.761"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/thrive/thrive-image-new.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
    </WrapperImage>
  );
};

export default ImageThrive;
