import styled from 'styled-components';

import { Col } from '@/lib/flexbox';

const TextCol = styled(Col)`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 32px;
  }
`;

export default TextCol;
