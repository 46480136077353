import styled, { css, keyframes } from 'styled-components';
import { timeAnimation } from '../../Platform';

const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 633px;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 0px;
  }
  50.1% {
    opacity: 1;
    stroke-dashoffset: 1266px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 633px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 20%;
  left: 30%;
  z-index: 0;
  width: 40%;

  svg {
    width: 100%;
    height: 100%;

    path {
      opacity: 0;
      stroke-dasharray: 633px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${line} ${timeAnimation}s ease-in-out;
        }
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 41%;
    left: 30.5%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    left: 31%;
  }
`;

const LineOne = ({ active }) => {
  return (
    <Wrapper $active={active}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="274"
        height="389"
        viewBox="0 0 274 389"
        fill="none"
      >
        <path
          stroke="url(#a)"
          strokeWidth="2.742"
          d="M1.73.94v87.743c0 16.153 13.095 29.247 29.248 29.247h212.045c16.152 0 29.247 13.095 29.247 29.248V388.47"
        />
        <defs>
          <linearGradient
            id="a"
            x1="-38.942"
            x2="281.114"
            y1=".94"
            y2="-87.201"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF8283" />
            <stop offset="1" stopColor="#5993EA" />
          </linearGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};

export default LineOne;
