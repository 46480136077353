import { Label, UpperText, H2 } from '@/components/core/Title';
import { Col, Container, Row } from '@/lib/flexbox';

import Wrapper from './Wrapper';
import Quote from './Quote';
import Numbers from './Numbers';
import ValueInfoContainer from './ValueInfoContainer';

const CustomerValue = ({ content }) => {
  if (!content) return null;

  const { label, title, subTitleCard, quote, logo, position, customerName, valueInfo } = content;

  const REFS = [4, 5, 6, 7];
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col xs={12}>
            {label && <UpperText data-testid="customerValue-label">{label}</UpperText>}
            {title && <H2 data-testid="customerValue-title">{title}</H2>}
            {subTitleCard && <Label data-testid="customerValue-subTitleCard">{subTitleCard}</Label>}
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={6}>
            <Quote quote={quote} customerName={customerName} position={position} logo={logo} />
          </Col>
          <Col xs={12} lg={6}>
            <ValueInfoContainer>
              {valueInfo.map((info, index) => (
                <Numbers
                  key={index}
                  number={info.numbers}
                  label={info.label}
                  change={info.change}
                  reference={REFS[index]}
                />
              ))}
            </ValueInfoContainer>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default CustomerValue;
