import Link from 'next/link';
import IconColor from '@/public/assets/platform/atlas/icon.svg';
import { Color, NoColor, Label } from '../../style';
import { Wrapper } from './styles';

const SvgAtlas = ({ active }) => {
  return (
    <Wrapper>
      <Link href="/solutions/atlas">
        <Color $active={active}>
          <IconColor />
        </Color>
        <NoColor $active={active}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="81"
            height="81"
            fill="none"
            viewBox="0 0 81 81"
          >
            <rect
              width="78.603"
              height="78.603"
              x=".978"
              y="1.178"
              stroke="#E6DCD4"
              strokeWidth="1.828"
              rx="39.301"
            />
            <path
              fill="#E6DCD4"
              d="m48.613 27.784-.404-.966v.126a9.15 9.15 0 0 0-1.553-2.082 9.157 9.157 0 0 0-2.947-1.965 8.863 8.863 0 0 0-3.476-.697c-1.814 0-3.56.529-5.047 1.536-1.503 1-2.695 2.435-3.359 4.056a8.916 8.916 0 0 0-.529 5.231 8.937 8.937 0 0 0 2.486 4.635 9.063 9.063 0 0 0 4.652 2.477 8.962 8.962 0 0 0 1.763.168 9.173 9.173 0 0 0 7.574-4.03 9.082 9.082 0 0 0 1.537-5.03 9.1 9.1 0 0 0-.697-3.468v.009Zm-9.506-1.999c.37-.067.73-.1 1.092-.1.739 0 1.461.142 2.124.428a5.557 5.557 0 0 1 2.494 2.048c.613.882.932 1.948.932 3.082a5.592 5.592 0 0 1-1.637 3.93l-.235.235a5.61 5.61 0 0 1-3.67 1.394 5.523 5.523 0 0 1-3.09-.932 5.43 5.43 0 0 1-2.04-2.51 5.576 5.576 0 0 1-.31-3.217 5.369 5.369 0 0 1 1.51-2.838 5.529 5.529 0 0 1 2.83-1.52ZM35.833 45.517l-.403-.965v.126a9.15 9.15 0 0 0-1.554-2.083 9.157 9.157 0 0 0-2.947-1.965 8.864 8.864 0 0 0-3.476-.696c-1.814 0-3.56.529-5.047 1.536-1.503 1-2.695 2.435-3.358 4.056a8.917 8.917 0 0 0-.53 5.231 8.938 8.938 0 0 0 2.486 4.635 9.063 9.063 0 0 0 4.652 2.477 8.962 8.962 0 0 0 1.763.168 9.173 9.173 0 0 0 7.574-4.03 9.082 9.082 0 0 0 1.537-5.03 9.1 9.1 0 0 0-.697-3.468v.008Zm-4.5 7.39-.236.235a5.61 5.61 0 0 1-3.67 1.394 5.524 5.524 0 0 1-3.09-.932 5.43 5.43 0 0 1-2.04-2.511 5.576 5.576 0 0 1-.31-3.216 5.37 5.37 0 0 1 1.51-2.838 5.528 5.528 0 0 1 2.83-1.52c.37-.067.731-.1 1.092-.1.74 0 1.461.142 2.125.428a5.503 5.503 0 0 1 2.493 2.048c.613.882.932 1.948.932 3.09a5.592 5.592 0 0 1-1.637 3.93v-.008ZM53.357 40.16a8.78 8.78 0 0 0-8.859 8.784c0 4.87 3.964 8.791 8.859 8.842a8.755 8.755 0 0 0 8.858-8.767c0-4.87-3.98-8.841-8.858-8.875v.017Z"
            />
          </svg>
        </NoColor>
        <Label $active={active}>Atlas</Label>
      </Link>
    </Wrapper>
  );
};

export default SvgAtlas;
