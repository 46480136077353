import styled, { css, keyframes } from 'styled-components';

import { WrapperLogoDefault, Color, NoColor, Label, labelAnimation } from '../../style';

import { timeAnimation } from '../../Platform';

const logoNocolor = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  54% {
    opacity: 0;
    transform: scale(1);
  }
  65% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const logoColor = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  54% {
    opacity: 1;
    transform: scale(1.1);
  }
  65% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 1572px;
  }
  10% {
    opacity: 1;
    stroke-dashoffset: 1572px;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 0px;
  }
  50.1% {
    opacity: 1;
    stroke-dashoffset: 3144px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 1572px;
  }
`;
const lineIcon = keyframes`
  0% {
		stroke-dashoffset: 345px;
  }
  20% {
		stroke-dashoffset: 345px;
  }
  100% {
		stroke-dashoffset: 690px;
  }
`;
const lineTwo = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 960px;
  }
	20% {
		opacity: 1;
    stroke-dashoffset: 960px;
  }
  50% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
		opacity: 1;
    stroke-dashoffset: 1920px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 960px;
  }
`;
const imageAnimation = keyframes`
  0% {
		opacity: 0;
  }
  50% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageTwoAnimation = keyframes`
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
export const Wrapper = styled.div`
  ${WrapperLogoDefault};
  z-index: 2;
  top: 14%;
  left: 24%;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${Color} {
      opacity: 1;
      transform: scale(1.1);
    }
    ${NoColor} {
      opacity: 0;
      transform: scale(1.1);
    }
    ${Label} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
  }
`;
export const AnimationColor = styled(Color)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoColor} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const AnimationNoColor = styled(NoColor)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoNocolor} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const AnimationLabel = styled(Label)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${labelAnimation} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;

export const WrapperImage = styled.div`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
export const ImageOne = styled.figure`
  position: absolute;
  top: 0;
  left: 5%;
  width: 44%;
  padding-bottom: 85%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 96%;
    position: absolute;
    top: 1%;
    left: 2%;
    height: 96%;
    z-index: 2;
    stroke-dasharray: 1572px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${line} 2s ease-in-out;
      }
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 94%;
      top: 2%;
      left: 3%;
      height: 94%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 96%;
      top: 1%;
      left: 2%;
      height: 96%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 93%;
      top: 2%;
      left: 3.5%;
      height: 94%;
    }
  }
`;
export const Line = styled.div`
  position: absolute;
  z-index: 0;
  width: 37.5%;
  top: 15.5%;
  left: 37%;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke-dasharray: 345px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${lineIcon} 2.5s ease-in-out;
        }
      }
    `};
`;
export const ImageTwo = styled.figure`
  position: absolute;
  top: 25%;
  left: 39.4%;
  width: 52%;
  padding-bottom: 34%;
  z-index: 0;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 91%;
    position: absolute;
    top: 3%;
    left: 4%;
    height: 89%;
    z-index: 2;
    stroke-dasharray: 960px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineTwo} 2.5s ease-in-out;
      }
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    left: 38.3%;

    svg {
      width: 89%;
      top: 4%;
      left: 5%;
      height: 87%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    left: 39.4%;
    svg {
      width: 91%;
      top: 3%;
      left: 4%;
      height: 89%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    left: 38.3%;
    svg {
      width: 89%;
      top: 4.5%;
      left: 5%;
      height: 86.5%;
    }
  }
`;
export const Icon = styled.div`
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 54%;
  width: 12%;
  height: 12%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
    top: 9%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
    top: 8%;
  }
`;
export const ImageProduct = styled.figure`
  position: absolute;
  top: 54%;
  left: 35%;
  width: 54%;
  padding-bottom: 31%;
  z-index: 2;

  img {
    object-fit: contain;
  }
`;
