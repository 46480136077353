import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/image';
import cn from 'classnames';

import getImgSrcWorkaround from '@/utils/images';
import Platform from './Platform';
import {
  ImageThrive,
  ImageBloom,
  ImageMove,
  ImagePredict,
  ImageOnCall,
  ImageAcademy,
  ImageAtlas,
} from './Platform/Svg';

const SolutionWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    position: absolute;
    height: calc(100vh - 100px);
    max-height: 683px;
    width: calc(100vh - 100px);
    max-width: 683px;
    top: 0;
    left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    margin-left: 13px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 0px;
  }
`;

const TempImageWrapper = styled.div`
  overflow: clip;
  position: relative;
  height: 0;
  padding-bottom: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  &.selected {
    opacity: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    opacity: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    ${(props) =>
      props.$mobileSpace &&
      css`
        padding-bottom: 80%;
      `};
  }
`;
export const WrapperVideos = styled.div`
  position: relative;
  max-height: 683px;
  padding-bottom: 100%;
`;
const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
`;
const CustomImage = styled(Image)`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
const Solution = ({ content, selected, index, isDesktop }) => {
  const { image, video, title } = content;
  const show = isDesktop ? selected : selected === index;
  const [animationMobile, setAnimationMobile] = useState(false);
  const formatTitle = title
    ?.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && index !== undefined && !isDesktop && !animationMobile) {
      setAnimationMobile(true);
    }
  }, [inView]);

  const ImageComponent = () => {
    switch (formatTitle) {
      case 'thrive':
        return <ImageThrive selected={!isDesktop ? animationMobile : show} />;
      case 'bloom':
        return <ImageBloom selected={!isDesktop ? animationMobile : show} />;
      case 'move':
        return <ImageMove selected={!isDesktop ? animationMobile : show} />;
      case 'predict':
        return <ImagePredict selected={!isDesktop ? animationMobile : show} />;
      case 'on-call':
        return <ImageOnCall selected={!isDesktop ? animationMobile : show} />;
      case 'academy':
        return <ImageAcademy selected={!isDesktop ? animationMobile : show} />;
      case 'atlas':
        return <ImageAtlas selected={!isDesktop ? animationMobile : show} />;
      default:
        return (
          <CustomImage
            $active={true}
            src={getImgSrcWorkaround(image?.data?.attributes.url)}
            alt={image?.data?.attributes.alternativeText || ''}
            fill
            sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 683px"
          />
        );
    }
  };

  return (
    <SolutionWrapper ref={ref}>
      <TempImageWrapper className={cn({ selected })} $mobileSpace={index !== 0}>
        {index === 0 ? (
          <Platform />
        ) : video.data?.attributes?.url ? (
          <WrapperVideos>
            <VideoContainer autoPlay playsInline loop muted>
              <source src={video.data?.attributes?.url} />
            </VideoContainer>
          </WrapperVideos>
        ) : (
          <ImageComponent />
        )}
      </TempImageWrapper>
    </SolutionWrapper>
  );
};

export default Solution;
