import styled from 'styled-components';
import DragSvg from '../../../public/assets/icons/drag.svg';

const Wrapper = styled.div`
  position: absolute;
  top: -50px;
  left: -37px;
  width: 74px;
  height: 72px;
  border-radius: 100%;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s cubic-bezier(0.2, 0.35, 0.25, 0.85);

  svg {
    overflow: visible;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 0.3s cubic-bezier(0.2, 0.35, 0.25, 0.85);
  }

  circle {
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.4s cubic-bezier(0.2, 0.35, 0.25, 0.85);

    @-moz-document url-prefix() {
      transform: scale(1);
    }
  }

  &.hovering,
  &.grabbing {
    opacity: 1;
  }

  &.hovering {
    circle {
      transform: scale(1);
    }
  }

  &.grabbing {
    circle {
      transform: scale(0.6);
    }
  }
`;

function CustomCursor(props) {
  return (
    <Wrapper {...props}>
      <DragSvg />
    </Wrapper>
  );
}

export default CustomCursor;
