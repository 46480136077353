import useButtonTracking from '@/utils/useButtonTracking';
import { Button } from '@/components/core/Button';
import { Col, Row } from '@/lib/flexbox';
import theme from '@/utils/styles-variables';
import getImgSrcWorkaround from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import Caption from './Caption';
import Content from './Content';
import Title from './Title';
import Video from './Video';
import Wrapper from './Wrapper';

const BUTTON_URL = '/request-demo/platform';
const BUTTON_TEXT = 'Request demo';

const Header = ({ content }) => {
  const { title, video, videoMobile, videoTablet } = content;
  const trackClick = useButtonTracking({ label: BUTTON_TEXT, url: BUTTON_URL, location: 'header' });

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );

  return (
    <Wrapper>
      {isMobile && (
        <Video autoPlay playsInline loop muted>
          <source src={getImgSrcWorkaround(videoMobile.data.attributes.url)} />
        </Video>
      )}

      {isTablet && (
        <Video autoPlay playsInline loop muted>
          <source src={getImgSrcWorkaround(videoTablet.data.attributes.url)} />
        </Video>
      )}

      {!isMobile && !isTablet && (
        <Video autoPlay playsInline loop muted>
          <source src={getImgSrcWorkaround(video.data.attributes.url)} />
        </Video>
      )}

      <Content>
        <Row>
          <Col xs={12}>
            <Title as="h1">{title}</Title>

            <Button variant="secondary-dark" href={BUTTON_URL} onClick={trackClick}>
              {BUTTON_TEXT}
            </Button>
          </Col>

          <Col xs={12}>
            <Caption>*Real Sword members</Caption>
          </Col>
        </Row>
      </Content>
    </Wrapper>
  );
};

export default Header;
