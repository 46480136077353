import styled, { css } from 'styled-components';

const Nav = ({ data, active, handleClick }) => {
  return (
    <>
      <Wrapper>
        <OuterWrapper>
          {data.map((item, index) => (
            <InnerWrapper
              key={index}
              $active={active === index}
              onClick={() => handleClick(index)}
              data-testid={`nav-${index}`}
            >
              <Item>{item.label}</Item>
            </InnerWrapper>
          ))}
        </OuterWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

const OuterWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  mix-blend-mode: multiply;
  border-radius: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-left: 20px;
    background-color: transparent;
    overflow: auto;
    white-space: nowrap;
    scrollbar-width: none; // hide scrollbar in Firefox

    ::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      padding-left: 10px;
    }
  }
`;

const Item = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s ease-in-out;
`;

const InnerWrapper = styled.div`
  background-color: transparent;
  border-radius: 300px;
  padding: 8px 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.neutral.dark};

    ${Item} {
      color: ${(props) => props.theme.colors.primary.default};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      background-color: initial;
      ${Item} {
        color: initial;
      }
    }
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${(props) => props.theme.colors.primary.light};
      transition: background-color 0.3s ease-in-out;

      ${Item} {
        color: ${(props) => props.theme.colors.primary.dark};
        transition: 0.3s;
        transition-timing-function: ease-in-out;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        &:hover {
          background-color: ${(props) => props.theme.colors.primary.light};
          ${Item} {
            color: ${(props) => props.theme.colors.primary.dark};
          }
        }
      }
    `}
`;

export default Nav;
