import Link from 'next/link';
import IconColor from '@/public/assets/platform/move/icon.svg';

import { AnimationColor, AnimationLabel, AnimationNoColor, Wrapper } from './styles';

const SvgMove = ({ active, delay }) => {
  return (
    <Wrapper>
      <Link href="/solutions/move">
        <AnimationColor $active={active} $delay={delay}>
          <IconColor />
        </AnimationColor>
        <AnimationNoColor $active={active} $delay={delay}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="81"
            height="81"
            fill="none"
            viewBox="0 0 81 81"
          >
            <rect
              width="78.603"
              height="78.603"
              x=".959"
              y="1.178"
              stroke="#E6DCD4"
              strokeWidth="1.828"
              rx="39.301"
            />
            <path
              fill="#E6DCD4"
              fillRule="evenodd"
              d="M35.87 46.334c4.025 2.507 5.298 7.85 2.845 11.938-2.454 4.087-7.701 5.367-11.727 2.86-4.025-2.506-5.298-7.85-2.845-11.937 2.454-4.088 7.701-5.367 11.726-2.86ZM44.556 18.556a12.976 12.976 0 0 1 11.024 5.29 12.902 12.902 0 0 1 2.135 4.588c.402 1.652.477 3.37.22 5.056a12.975 12.975 0 0 1-7.34 9.775 13.005 13.005 0 0 1-7.453 1.062 12.99 12.99 0 0 1-6.788-3.258 12.958 12.958 0 0 1-3.417-13.998 12.974 12.974 0 0 1 11.628-8.516h-.01Zm-6.957 16.47a8.26 8.26 0 0 0 3.198 3.595 8.26 8.26 0 0 0 6.268.955 8.351 8.351 0 0 0 6.334-8.446 8.291 8.291 0 0 0-1.583-4.55 8.208 8.208 0 0 0-3.843-2.889 8.243 8.243 0 0 0-4.803-.257 8.337 8.337 0 0 0-6.24 6.816 8.292 8.292 0 0 0 .67 4.775Z"
              clipRule="evenodd"
            />
          </svg>
        </AnimationNoColor>
        <AnimationLabel $active={active} $delay={delay}>
          Move
        </AnimationLabel>
      </Link>
    </Wrapper>
  );
};

export default SvgMove;
