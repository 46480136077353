import styled from 'styled-components';

export const ValueInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-left: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-left: 0;
    margin-top: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
  }
`;

export default ValueInfoContainer;
