import Link from 'next/link';
import IconColor from '@/public/assets/platform/predict/icon.svg';

import { AnimationColor, AnimationLabel, AnimationNoColor, Wrapper } from './styles';

const SvgPredict = ({ active }) => {
  return (
    <Wrapper>
      <Link href="/solutions/predict">
        <AnimationColor $active={active}>
          <IconColor />
        </AnimationColor>
        <AnimationNoColor $active={active}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="88"
            fill="none"
            viewBox="0 0 88 88"
          >
            <rect width="86" height="86" x="1" y="1" fill="#F7F4F2" rx="43" />
            <rect width="86" height="86" x="1" y="1" stroke="#E6DCD4" strokeWidth="2" rx="43" />
            <path
              fill="#E6DCD4"
              fillRule="evenodd"
              d="M28.15 25.434c4.494.015 8.152 3.707 8.152 8.204 0 4.497-3.643 8.143-8.151 8.097C23.643 41.69 20 38.06 20 33.546a8.088 8.088 0 0 1 8.15-8.112Zm26.266 26.264c4.492.016 8.15 3.707 8.15 8.204 0 4.497-3.642 8.143-8.15 8.098-4.508-.046-8.151-3.677-8.151-8.189a8.088 8.088 0 0 1 8.15-8.113Zm-18.114 8.204c0-4.497-3.658-8.188-8.151-8.204A8.088 8.088 0 0 0 20 59.811c0 4.512 3.643 8.143 8.15 8.189 4.509.045 8.152-3.6 8.152-8.098Z"
              clipRule="evenodd"
            />
            <path
              fill="#E6DCD4"
              d="M67.006 28.092a12.842 12.842 0 0 0-2.826-4.251 13.183 13.183 0 0 0-4.263-2.845A12.936 12.936 0 0 0 54.896 20c-2.59 0-5.147.774-7.294 2.229a13.298 13.298 0 0 0-4.863 5.879 13.047 13.047 0 0 0-.742 7.602A13.099 13.099 0 0 0 52.29 46.015c2.542.505 5.194.237 7.578-.759a13.204 13.204 0 0 0 5.905-4.836c1.437-2.181 2.227-4.71 2.227-7.302 0-1.707-.332-3.414-.995-5.026Zm-6.174 11a8.348 8.348 0 0 1-5.92 2.466 8.293 8.293 0 0 1-4.658-1.423 8.346 8.346 0 0 1-3.079-3.793 8.537 8.537 0 0 1-.458-4.868 8.238 8.238 0 0 1 2.29-4.299 8.43 8.43 0 0 1 4.278-2.307c1.627-.3 3.3-.158 4.832.49a8.467 8.467 0 0 1 3.773 3.113 8.363 8.363 0 0 1 1.421 4.679 8.402 8.402 0 0 1-2.463 5.942h-.016Z"
            />
          </svg>
        </AnimationNoColor>
        <AnimationLabel $active={active}>Predict</AnimationLabel>
      </Link>
    </Wrapper>
  );
};

export default SvgPredict;
