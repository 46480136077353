import styled from 'styled-components';
import { Section } from '@/components/core';
import { Container } from '@/lib/flexbox';
import { StyledContainer } from '@/components/shared/Intro/styles';

export const CustomSection = styled(Section)`
  overflow: clip;
`;
export const CustomContainer = styled(Container)`
  position: relative;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  z-index: 2;

  .intro {
    ${StyledContainer} {
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding: 0;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 40px;
  }
`;

export const Cards = styled.ul`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(2, minmax(124px, 1fr));
  align-self: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 24px;
  }
`;
export const Items = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 80px 0;
  max-width: 380px;
  .title {
    text-align: center;
    background: -webkit-linear-gradient(323deg, #ff8283, #5993ea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .description {
    text-align: center;
    word-wrap: break-word;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 0;
    gap: 16px;
    max-width: 332px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 40px 0;
  }
`;
export const WrapperVideos = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 192px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 144px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 296px;
  }
`;
export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  border-radius: 40px;
  max-width: 800px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 24px;
  }
`;
export const VideoText = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  height: 100%;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.grey.dark}B3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  gap: 24px;

  .videoTitle {
    color: ${(props) => props.theme.colors.white};
    flex: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    padding: 24px;
    justify-content: center;
    gap: 16px;
    border-radius: 24px;

    .videoTitle {
      flex: initial;
      text-align: center;
    }
  }
`;
const defaultCircle = `
  position: absolute;
  top: 50%;
  left: 50%;
  width: 720px;
  height: 720px;
  border-radius: 100%;
  opacity: .1;
  z-index: 0;
`;
export const CircleOne = styled.div`
  ${defaultCircle};
  transform: translateX(-79%) translateY(-45%);
  background: rgb(89, 147, 234);
  background: linear-gradient(0deg, rgba(89, 147, 234, 1) 0%, rgba(89, 147, 234, 0) 100%);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 480px;
    height: 480px;
    transform: translateX(-87%) translateY(-37%);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 360px;
    height: 360px;
    transform: translateX(-70%) translateY(-33%);
  }
`;
export const CircleTwo = styled.div`
  ${defaultCircle};
  transform: translateX(-20%) translateY(-45%);
  background: rgb(255, 130, 131);
  background: linear-gradient(0deg, rgba(255, 130, 131, 1) 0%, rgba(255, 130, 131, 0) 100%);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 480px;
    height: 480px;
    transform: translateX(-13%) translateY(-37%);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 360px;
    height: 360px;
    transform: translateX(-30%) translateY(-33%);
  }
`;
