import styled from 'styled-components';

import { Section } from '@/components/core';

import { Container } from '@/lib/flexbox';

import Wrapper from './Wrapper';
import theme from '@/utils/styles-variables';
import { GAP, GAP_SM, GAP_XS } from '@/components/core/Section/Wrapper';
import OurSolutionsInner from './OurSolutionsInner';

const OurSolutions = ({ content, spacerTop }) => {
  return (
    <CustomSection spacerTop={spacerTop} color={theme.colors.neutral.default}>
      <Wrapper>
        <Container>
          <OurSolutionsInner content={content} />
        </Container>
      </Wrapper>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  --mask-size: ${GAP}px;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) calc(100% - var(--mask-size)),
    rgba(255, 255, 255, 0)
  );
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    --mask-size: ${GAP_SM}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --mask-size: ${GAP_XS}px;
  }
`;

export default OurSolutions;
