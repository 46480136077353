import { CircleFilled, CircleOutlined } from '@/components/core/Circle';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import theme from '@/utils/styles-variables';

const GraphicElements = () => {
  const isTablet = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_md}px)`);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <>
      {!isTablet && !isMobile && (
        <>
          <CircleFilled
            style={{ position: 'absolute', top: '-100px', left: '-220px', opacity: 0.5 }}
            size="286px"
            color={theme.colors.neutral.default}
          />

          <CircleFilled
            style={{ position: 'absolute', top: '-30px', left: '150px' }}
            size="38px"
            color={theme.colors.primary.light}
          />

          <CircleFilled
            style={{ position: 'absolute', top: '-80px', right: '50px' }}
            size="28px"
            color={theme.colors.secondary.default}
          />

          <CircleOutlined
            style={{ position: 'absolute', bottom: '-128px', left: '230px' }}
            size="48px"
            color={theme.colors.secondary.default}
          />

          <CircleOutlined
            style={{ position: 'absolute', bottom: '-150px', right: '230px' }}
            size="27px"
            color={theme.colors.secondary.light}
          />
        </>
      )}

      {isTablet && !isMobile && (
        <>
          <CircleFilled
            style={{ position: 'absolute', top: '-50px', left: '175px' }}
            size="38px"
            color={theme.colors.primary.light}
          />

          <CircleFilled
            style={{ position: 'absolute', top: '-70px', right: '110px' }}
            size="28px"
            color={theme.colors.secondary.default}
          />

          <CircleOutlined
            style={{ position: 'absolute', bottom: '-65px', left: '120px' }}
            size="48px"
            color={theme.colors.secondary.default}
          />

          <CircleOutlined
            style={{ position: 'absolute', bottom: '-70px', right: '185px' }}
            size="27px"
            color={theme.colors.secondary.light}
          />
        </>
      )}

      {isTablet && isMobile && (
        <>
          <CircleFilled
            style={{ position: 'absolute', top: '-18px', left: '40px' }}
            size="28px"
            color={theme.colors.secondary.default}
          />

          <CircleOutlined
            style={{ position: 'absolute', bottom: '-52px', right: '40px' }}
            size="22px"
            color={theme.colors.primary.light}
          />
        </>
      )}
    </>
  );
};

export default GraphicElements;
