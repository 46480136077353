import { CircleFilled } from '@/components/core/Circle';
import theme from '@/utils/styles-variables';
import styled from 'styled-components';

const CustomCircleFilledBig = styled(CircleFilled)`
  width: 408px;
  height: 408px;
  position: absolute;
  bottom: -70px;
  right: -24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

const CustomCircleFilledSmall = styled(CircleFilled)`
  width: 88px;
  height: 88px;
  position: absolute;
  top: -34px;
  left: -28px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

const GraphicElements = () => (
  <>
    <CustomCircleFilledBig color={theme.colors.primary.light} />
    <CustomCircleFilledSmall color={theme.colors.secondary.light} />
  </>
);

export default GraphicElements;
