import { SplitImageWithMetrics } from '@/components/shared/SplitImageWithMetrics';

import { Section } from '@/components/core';

import theme from '@/utils/styles-variables';

const MemberImpact = ({ content, bgColor = theme.colors.neutral.default, spacerTop = true }) => (
  <Section color={bgColor} spacerTop={spacerTop}>
    <SplitImageWithMetrics content={content} withRefs />
  </Section>
);

export default MemberImpact;
