import Image from 'next/image';

import IconColor from '@/public/assets/platform/bloom/icon.svg';
import { Icon, ImageOne, ImageProduct, ImageTwo, Line, WrapperImage } from './styles';

const ImageThrive = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="269"
          height="549"
          fill="none"
          viewBox="0 0 269 549"
        >
          <rect
            width="266"
            height="546"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="269"
              x2="-169.434"
              y1="549"
              y2="301.629"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/bloom/image-app.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="258"
          height="106"
          fill="none"
          viewBox="0 0 258 106"
        >
          <path stroke="url(#a)" strokeWidth="3" d="M256 106V34c0-17.673-14.327-32-32-32H0" />
          <defs>
            <linearGradient
              id="a"
              x1="0"
              x2="172.45"
              y1="106"
              y2="-76.814"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="310"
          height="202"
          fill="none"
          viewBox="0 0 310 202"
        >
          <rect
            width="307"
            height="199"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="310"
              x2="148.436"
              y1="202"
              y2="-83.508"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/bloom/image-person.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
      <ImageProduct>
        <Image
          src="/assets/platform/bloom/image-product.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageProduct>
    </WrapperImage>
  );
};

export default ImageThrive;
