import { useEffect, useState } from 'react';
import {
  LineFour,
  LineOne,
  LineThree,
  LineTwo,
  SvgAcademy,
  SvgAtlas,
  SvgBloom,
  SvgMove,
  SvgOnCall,
  SvgPredict,
  SvgThrive,
} from './Svg';
import { Wrapper } from './style';

export const timeAnimation = 3;

const Platform = () => {
  const [bloom, setBloom] = useState(false);
  const [bloomDelay, setBloomDelay] = useState(0);
  const [academy, setAcademy] = useState(false);
  const [onCall, setOnCall] = useState(false);
  const [thriveDelay, setThriveDelay] = useState(0);
  const [thrive, setThrive] = useState(false);
  const [move, setMove] = useState(false);
  const [moveDelay, setMoveDetaly] = useState(1);
  const [academyDelay, setAcademyDetaly] = useState(0.5);
  const [predict, setPredict] = useState(false);
  const [lineOne, setLineOne] = useState(false);
  const [lineTwo, setLineTwo] = useState(false);
  const [lineThree, setLineThree] = useState(false);
  const [lineFour, setLineFour] = useState(false);

  const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  const firstAnimation = async () => {
    setLineOne(true);
    setBloom(true);
    setAcademy(true);
    setOnCall(true);

    await delay(2800);
    setAcademy(false);
    await delay(200);
    setAcademy(false);
    setLineOne(false);
    setBloom(false);
    setOnCall(false);
    secondAnimation();
  };

  const secondAnimation = async () => {
    setAcademyDetaly(0.7);
    setLineTwo(true);
    setThrive(true);
    setAcademy(true);
    setMoveDetaly(1);
    setMove(true);

    await delay(2800);
    setAcademy(false);
    setThrive(false);
    await delay(200);
    setAcademyDetaly(0.5);
    setAcademy(false);
    setLineTwo(false);
    setThrive(false);
    setMove(false);
    threeAnimation();
  };

  const threeAnimation = async () => {
    setThriveDelay(0.7);
    setLineThree(true);
    setPredict(true);
    setThrive(true);
    setOnCall(true);

    await delay(3000);
    setThriveDelay(0);
    setLineThree(false);
    setPredict(false);
    setThrive(false);
    setOnCall(false);
    fourAnimation();
  };
  const fourAnimation = async () => {
    setBloomDelay(1);
    setLineFour(true);
    setMoveDetaly(0);
    setMove(true);
    setBloom(true);
    await delay(2900);
    setBloom(false);
    await delay(100);
    setLineFour(false);
    setMove(false);
    setMoveDetaly(1);
    setBloomDelay(0);
    firstAnimation();
  };

  useEffect(() => {
    firstAnimation();
  }, []);

  return (
    <Wrapper>
      <SvgBloom active={bloom} delay={bloomDelay} />
      <SvgPredict active={predict} />
      <SvgAcademy active={academy} delay={academyDelay} />
      <SvgAtlas />
      <SvgThrive active={thrive} delay={thriveDelay} />
      <SvgOnCall active={onCall} />
      <SvgMove active={move} delay={moveDelay} />
      <LineOne active={lineOne} />
      <LineTwo active={lineTwo} />
      <LineThree active={lineThree} />
      <LineFour active={lineFour} />
    </Wrapper>
  );
};

export default Platform;
