import Wrapper from './Wrapper';
import GraphicElements from './GraphicElements';
import StyledContainer from './StyledContainer';
import Grid from './Grid';

import { Title } from '@/components/core/Title';

import { Section } from '@/components/core';

const LearnMore = ({ content }) => {
  if (!content) return null;

  const { title, learnCard } = content;

  return (
    <Section>
      <Wrapper>
        <StyledContainer>
          {title && <Title>{title}</Title>}
          {learnCard && <Grid content={learnCard} />}
          <GraphicElements />
        </StyledContainer>
      </Wrapper>
    </Section>
  );
};

export default LearnMore;
