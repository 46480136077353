import styled from 'styled-components';
import MarkdownRichText from '@/components/shared/MarkdownRichText';

import DownArrow from '../../../public/assets/shapes/customer-impact-down-arrow.svg';
import { Paragraph } from '@/components/core';

const CHANGE = {
  NONE: 'none',
  GROWTH: 'growth',
  DECLINE: 'decline',
};

const Numbers = ({ number, label, change }) => {
  if (!number || !label) return null;
  return (
    <Wrapper>
      <NumberWrapper>
        {change === CHANGE.DECLINE && <DownArrow />}
        {change === CHANGE.GROWTH && <UpArrow />}
        <Number>
          <MarkdownRichText>{number}</MarkdownRichText>
        </Number>
      </NumberWrapper>
      <Label markdownProps={{ elements: ['a'] }} data-testid="numbers-label">
        {label}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  height: 215px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 140px;
    padding-right: 40px;
  }
`;

const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  strong {
    font-weight: 700;
    font-size: 45px;
    line-height: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 30px;

    strong {
      font-size: 40px;
      line-height: 40px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 22px;
    line-height: 34px;

    strong {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;

const Label = styled(Paragraph)`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;

  sup {
    color: ${(props) => props.theme.colors.primary.default};
    font-size: 80%;
    vertical-align: top;
    line-height: 1;
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

const UpArrow = styled(DownArrow)`
  transform: rotate(180deg);
`;

export default Numbers;
