import { Wrapper } from './Wrapper';
import ImagesGrid from '../Info/ImagesGrid';
import InfoData from '../Info/InfoData';

const Info = ({ data, position }) => {
  const { title, subTitle, link, buttonLabel, bigImage } = data;

  return (
    <Wrapper>
      <ImagesGrid bigImage={bigImage} position={position} />
      <InfoData title={title} subTitle={subTitle} link={link} linkLabel={buttonLabel} />
    </Wrapper>
  );
};

export default Info;
