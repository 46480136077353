import styled, { css, keyframes } from 'styled-components';

import { WrapperLogoDefault, Color, NoColor, Label, labelAnimation } from '../../style';

import { timeAnimation } from '../../Platform';

const logoNocolor = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  54% {
    opacity: 0;
    transform: scale(1);
  }
  65% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const logoColor = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  54% {
    opacity: 1;
    transform: scale(1.1);
  }
  65% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 736px;
  }
  30% {
    opacity: 1;
    stroke-dashoffset: 736px;
  }
  60% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  60.1% {
    opacity: 1;
    stroke-dashoffset: 1472px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 736px;
  }
`;
const lineIcon = keyframes`
  0% {
		stroke-dashoffset: 1383px;
  }
  30% {
		stroke-dashoffset: 1383px;
  }
  100% {
		stroke-dashoffset: 0;
  }
`;
const lineTwo = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 736px;
  }
  10% {
		opacity: 1;
    stroke-dashoffset: 736px;
  }
  50% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
		opacity: 1;
    stroke-dashoffset: 1472px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 736px;
  }
`;
const lineThree = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 736px;
  }
  30% {
		opacity: 1;
    stroke-dashoffset: 736px;
  }
  50% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
		opacity: 1;
    stroke-dashoffset: 1472px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 736px;
  }
`;
const imageAnimation = keyframes`
  0% {
		opacity: 0;
  }
  50% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageTwoAnimation = keyframes`
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageThreeAnimation = keyframes`
  0% {
		opacity: 0;
  }
  70% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
export const Wrapper = styled.div`
  ${WrapperLogoDefault};
  top: 31%;
  left: 7%;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${Color} {
      opacity: 1;
      transform: scale(1.1);
    }
    ${NoColor} {
      opacity: 0;
      transform: scale(1.1);
    }
    ${Label} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
  }
`;
export const AnimationColor = styled(Color)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoColor} ${timeAnimation}s ease-in-out;
    `};
`;
export const AnimationNoColor = styled(NoColor)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoNocolor} ${timeAnimation}s ease-in-out;
    `};
`;
export const AnimationLabel = styled(Label)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${labelAnimation} ${timeAnimation}s ease-in-out;
    `};
`;
export const WrapperImage = styled.div`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
export const ImageOne = styled.figure`
  position: absolute;
  top: 0;
  left: 17%;
  width: 35%;
  padding-bottom: 34%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 90%;
    position: absolute;
    top: 3%;
    left: 5%;
    height: 89%;
    z-index: 2;
    stroke-dasharray: 736px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${line} 2s ease-in-out;
      }
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 86%;
      top: 4%;
      left: 7%;
      height: 86%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 90%;
      top: 3%;
      left: 5%;
      height: 89%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 86%;
      top: 5%;
      left: 7%;
      height: 86%;
    }
  }
`;
export const ImageTextOne = styled.div`
  position: absolute;
  top: 46%;
  left: 49%;
  width: 41%;
  padding-bottom: 24%;
  overflow: clip;
  z-index: 2;

  img {
    object-fit: contain;
    opacity: 1;
  }

  ${(props) =>
    props.$active &&
    css`
      img {
        animation: ${imageThreeAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    top: 52%;
  }
`;
export const ImageTextTwo = styled.div`
  position: absolute;
  top: 63%;
  left: 20%;
  width: 40%;
  padding-bottom: 23%;
  overflow: clip;
  z-index: 2;

  img {
    object-fit: contain;
  }

  ${(props) =>
    props.$active &&
    css`
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    top: 80%;
  }
`;
export const ImageTextThree = styled.div`
  position: absolute;
  top: 22%;
  left: 8%;
  width: 40%;
  padding-bottom: 24%;
  overflow: clip;
  z-index: 2;

  img {
    object-fit: contain;
  }

  ${(props) =>
    props.$active &&
    css`
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    top: 26%;
  }
`;
export const Line = styled.div`
  position: absolute;
  z-index: 0;
  width: 53%;
  top: 12%;
  left: 26%;

  svg {
    width: 100%;
    height: 100%;
    stroke-dasharray: 1383px;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineIcon} 2.5s ease-in-out;
      }
    `};
`;
export const ImageTwo = styled.figure`
  position: absolute;
  top: 45.5%;
  left: 11.5%;
  width: 35%;
  padding-bottom: 34%;
  z-index: 1;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 90%;
    position: absolute;
    top: 3%;
    left: 5%;
    height: 89%;
    z-index: 2;
    stroke-dasharray: 736px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineTwo} 2s ease-in-out;
      }
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 86%;
      top: 4%;
      left: 7%;
      height: 86%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 90%;
      top: 3%;
      left: 5%;
      height: 89%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    top: 58%;

    svg {
      width: 86%;
      top: 5%;
      left: 7%;
      height: 86%;
    }
  }
`;
export const ImageThree = styled.figure`
  position: absolute;
  top: 25%;
  left: 60%;
  width: 35%;
  padding-bottom: 34%;
  z-index: 1;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 90%;
    position: absolute;
    top: 3%;
    left: 5%;
    height: 89%;
    z-index: 2;
    stroke-dasharray: 736px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineThree} 2.5s ease-in-out;
      }
      img {
        animation: ${imageThreeAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 86%;
      top: 4%;
      left: 7%;
      height: 86%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 90%;
      top: 3%;
      left: 5%;
      height: 89%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 86%;
      top: 5%;
      left: 7%;
      height: 86%;
    }
  }
`;
export const Icon = styled.div`
  position: absolute;
  z-index: 1;
  top: 6%;
  left: 57.5%;
  width: 12%;
  height: 12%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
    top: 5%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
    top: 6%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
    top: 5%;
  }
`;
