import styled from 'styled-components';
import { H3 } from '@/components/core/Title';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  ${H3} {
    font-size: 30px;
    line-height: 40px;
    text-align: left;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
  }
`;
