import styled from 'styled-components';
import cn from 'classnames';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

const SemiCircle = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  & + & {
    margin-top: 4px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 2px solid ${(props) => props.theme.colors.grey.default};
  }

  &.active {
    background-color: ${(props) => props.theme.colors.grey.default};
  }
`;

const Indicator = ({ count, selected }) => {
  return (
    <Wrapper>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <SemiCircle
            key={index}
            className={cn({ active: index === selected })}
            data-testid={index}
          />
        ))}
    </Wrapper>
  );
};

export default Indicator;
