import { memo, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import theme from '@/utils/styles-variables';
import { trackButtonClicked } from '@/utils/track';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import { Button } from '@/components/core/Button';
import Card from './Card';
import CustomCursor from './CustomCursor';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';

const Wrapper = styled.div`
  position: relative;
  padding-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 0;
  }

  .cursor-wrapper {
    position: relative;
  }

  .swiper-slide {
    width: auto;
  }
`;

const MobilePrefix = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    font-family: ${(props) => props.theme.typography.family.title};
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    text-align: center;

    overflow: hidden;
    opacity: 0;
    transform: translate(-50%, 25px);
    transition:
      transform 300ms ease,
      opacity 300ms ease;
    &.active {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const LookingForSlider = ({ content, buttonLabel, title }) => {
  const router = useRouter();
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);
  /* Slider */
  const startPosition = useRef(0);
  const swiperSlides = useRef(content.length > 3 ? content.length : content.concat(content));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shownPrefix, setShownPrefix] = useState(true);
  const sliderRef = useRef();

  /* Custom cursor */
  const [hovering, setHovering] = useState(false);
  const [grabbing, setGrabbing] = useState(false);
  const [position, setPosition] = useState([0, 0]);

  const handleMouseMove = useCallback((e) => {
    const rect = sliderRef?.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    setPosition([e.clientX - rect.left, e.clientY - rect.top]);
    setHovering(true);
  }, []);

  const handleClick = () => {
    trackButtonClicked({
      buttonType: 'navigation',
      buttonText: buttonLabel,
      buttonLocation: title,
      destination: swiperSlides.current[currentSlide].link,
      url: router.pathname,
    });
  };

  return (
    <Wrapper>
      {swiperSlides.current.map((c, index) => (
        <MobilePrefix
          key={`${c.label}${index}`}
          className={cn({ active: shownPrefix && currentSlide === index })}
        >
          {c.prefix}
        </MobilePrefix>
      ))}

      <div
        className={cn('cursor-wrapper', { animate: shownPrefix })}
        ref={sliderRef}
        onMouseLeave={() => setHovering(false)}
        onMouseEnter={handleMouseMove}
        onMouseMove={handleMouseMove}
      >
        {isDesktop && (
          <CustomCursor
            className={cn({ hovering, grabbing })}
            style={{
              transform: `translate(${position[0]}px, ${position[1]}px)`,
            }}
          />
        )}
        <Swiper
          slidesPerView={'auto'}
          a11y={true}
          allowTouchMove={false}
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          speed={500}
          grabCursor={true}
          modules={[A11y]}
          onTouchMove={(_, e) => handleMouseMove(e)}
          onTouchEnd={(swiper, e) => {
            const sliderQuantify = swiper.slides.length - 1;
            let position = 0;

            if (isDesktop) {
              const rect = sliderRef?.current?.getBoundingClientRect();
              const middleValue = rect.width / 2;
              const distance =
                e.clientX > middleValue
                  ? startPosition.current + e.clientX
                  : startPosition.current - e.clientX;
              position = distance > 0 ? swiper.realIndex + 1 : swiper.realIndex - 1;
            } else {
              position = swiper.realIndex + 1;
            }

            setTimeout(() => {
              swiper.slideToLoop(
                position < 0 ? sliderQuantify : position > sliderQuantify ? 0 : position,
              );
            }, 300);

            setShownPrefix(false);
            setGrabbing(false);
          }}
          onTouchStart={(_, e) => {
            startPosition.current = e.clientX;
            setGrabbing(true);
          }}
          onSlideChange={(swiper) => {
            setCurrentSlide(swiper.realIndex);
            setShownPrefix(true);
          }}
        >
          {swiperSlides.current.map((card, index) => (
            <SwiperSlide key={`${card.label}${index}`}>
              <Card {...card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <ButtonWrapper>
        <Button
          variant="tertiary"
          href={swiperSlides.current[currentSlide]?.link}
          onClick={handleClick}
        >
          {buttonLabel}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default memo(LookingForSlider);
