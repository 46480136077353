import styled from 'styled-components';

import { H2 } from '@/components/core/Title';

const Wrapper = styled.div`
  & ${H2} {
    max-width: 600px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    & ${H2} {
      max-width: 400px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    & ${H2} {
      max-width: 340px;
    }
  }
`;

export default Wrapper;
