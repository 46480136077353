import styled from 'styled-components';
import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';
import GraphicElements from '@/components/homepage/OurDna/GraphicElements';
import GraphicElementsAlt from '@/components/homepage/OurDna/GraphicElementsAlt';

const GridImages = ({ bigImage, position }) => {
  return (
    <Wrapper>
      <Image
        src={getImgSrcWorkaround(bigImage.data.attributes.url)}
        alt={bigImage.data.attributes.alternativeText || ''}
        fill
        sizes="(max-width: 767px)  100vw,
               (max-width: 991px) 50vw,
               620px"
      />
      {position === 0 && <GraphicElements />}
      {position === 2 && <GraphicElementsAlt />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 620px;
  padding-bottom: 42.4%;

  img {
    object-fit: contain;
    object-position: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: flex;
    padding-bottom: 60%;
  }
`;

export default GridImages;
