import { Heading } from '@swordhealth/ui-corporate';
import IconPlay from '@swordhealth/ui-corporate/icons/system/play-solid.svg';

import useReducedMotion from '@/utils/useReducedMotion';
import { Paragraph } from '@/components/core';
import { Intro } from '@/components/shared';
import {
  Cards,
  CircleOne,
  Items,
  VideoContainer,
  VideoText,
  Wrapper,
  WrapperVideos,
  CustomContainer,
  CircleTwo,
  CustomSection,
} from './styles';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import VideoPlayerModal from '@/components/shared/VideoPlayerModal';
import { useState } from 'react';
import { Button } from '@/components/core/Button';

const AiCare = ({ content }) => {
  const { header, cards, video } = content;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const prefersReducedMotion = useReducedMotion();
  const previewVideoData = getImgData(video.previewVideo);
  const videoUrl = typeof video.video === 'string' ? video.video : getImgData(video.video)?.url;

  return (
    <CustomSection id="ai-care">
      <CustomContainer>
        <CircleOne />
        <CircleTwo />
        <Wrapper>
          <Intro {...header} linkWithIcon className="intro" as="div" xs="0" sm="0" md="0" />
          <Cards>
            {cards.map((item) => (
              <Items key={item.id}>
                <Heading as="h3" size="lg" className="title">
                  {item.title}
                </Heading>
                <Paragraph
                  forwardedAs="p"
                  size="md"
                  markdownProps={{ elements: ['a'] }}
                  className="description"
                >
                  {item.description}
                </Paragraph>
              </Items>
            ))}
          </Cards>
          <WrapperVideos>
            <VideoPlayerModal
              modalIsOpen={modalIsOpen}
              closeModal={() => setModalIsOpen(false)}
              videoURL={videoUrl}
            />
            <VideoContainer autoPlay={!prefersReducedMotion} playsInline loop muted>
              <source
                src={previewVideoData?.url ? getImgSrcWorkaround(previewVideoData.url) : videoUrl}
              />
            </VideoContainer>
            <VideoText>
              <Heading as="h5" size="sm" className="videoTitle">
                {video.videoTitle ?? video.previewVideo.data.attributes.caption}
              </Heading>
              <Button variant="secondary-dark" onClick={() => setModalIsOpen(true)}>
                <IconPlay /> {video.buttonLabel || 'Play video'}
              </Button>
            </VideoText>
          </WrapperVideos>
        </Wrapper>
      </CustomContainer>
    </CustomSection>
  );
};

export default AiCare;
