import Link from 'next/link';

import { AnimationColor, AnimationLabel, AnimationNoColor, Wrapper } from './styles';
import IconColor from '@/public/assets/platform/thrive/icon.svg';

const SvgThrive = ({ active, delay }) => {
  return (
    <Wrapper>
      <Link href="/solutions/thrive">
        <AnimationColor $active={active} $delay={delay}>
          <IconColor />
        </AnimationColor>
        <AnimationNoColor $active={active} $delay={delay}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="81"
            height="81"
            fill="none"
            viewBox="0 0 81 81"
          >
            <rect
              width="78.603"
              height="78.603"
              x="1.429"
              y="1.168"
              stroke="#E6DCD4"
              strokeWidth="1.828"
              rx="39.301"
            />
            <path
              fill="#E6DCD4"
              d="M28.08 48.943c-.071 7.454 5.73 13.463 12.874 13.463 7.148 0 13.007-6.043 13.04-13.463H28.08Z"
            />
            <path
              fill="#E6DCD4"
              fillRule="evenodd"
              d="M40.276 18.547a12.908 12.908 0 0 1 10.967 5.262 12.836 12.836 0 0 1 2.123 4.564c.4 1.644.475 3.353.22 5.03a12.906 12.906 0 0 1-7.303 9.724 12.936 12.936 0 0 1-7.414 1.057 12.923 12.923 0 0 1-6.752-3.24 12.892 12.892 0 0 1-3.4-13.926 12.914 12.914 0 0 1 11.568-8.471h-.01Zm-6.92 16.384a8.218 8.218 0 0 0 3.18 3.576 8.216 8.216 0 0 0 6.236.95 8.308 8.308 0 0 0 6.3-8.401 8.25 8.25 0 0 0-1.573-4.527 8.165 8.165 0 0 0-3.824-2.873 8.2 8.2 0 0 0-4.778-.257 8.293 8.293 0 0 0-6.208 6.781 8.249 8.249 0 0 0 .666 4.75Z"
              clipRule="evenodd"
            />
          </svg>
        </AnimationNoColor>
        <AnimationLabel $active={active} $delay={delay}>
          Thrive
        </AnimationLabel>
      </Link>
    </Wrapper>
  );
};

export default SvgThrive;
