import Image from 'next/image';

import IconColor from '@/public/assets/platform/atlas/icon.svg';
import { Icon, ImageOne, ImageTwo, ImageThree, Line, WrapperImage } from './styles';

const ImageAtlas = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="213"
          height="431"
          fill="none"
          viewBox="0 0 213 431"
        >
          <rect
            width="210"
            height="428"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="213"
              x2="-132.718"
              y1="431"
              y2="234.262"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/atlas/image-app.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="102"
          height="219"
          fill="none"
          viewBox="0 0 102 219"
        >
          <path stroke="url(#a)" strokeWidth="3" d="M102 2H34C16.327 2 2 16.327 2 34v185" />
          <defs>
            <linearGradient
              id="a"
              x1="102"
              x2="-35.645"
              y1="2"
              y2="29.317"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="336"
          height="257"
          fill="none"
          viewBox="0 0 336 257"
        >
          <rect
            width="333"
            height="254"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="336"
              x2="114.973"
              y1="257"
              y2="-75.749"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/atlas/image-person.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
      <ImageThree $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="214"
          height="435"
          fill="none"
          viewBox="0 0 214 435"
        >
          <rect
            width="211"
            height="432"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="214"
              x2="-134.113"
              y1="435"
              y2="237.799"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/atlas/image-app-two.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageThree>
    </WrapperImage>
  );
};

export default ImageAtlas;
