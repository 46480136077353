import Image from 'next/image';

import IconColor from '@/public/assets/platform/predict/icon.svg';
import {
  Icon,
  ImageOne,
  ImageTextOne,
  ImageTextThree,
  ImageTextTwo,
  ImageThree,
  ImageTwo,
  Line,
  WrapperImage,
} from './styles';

const ImagePredict = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
          fill="none"
          viewBox="0 0 200 200"
        >
          <rect
            width="197"
            height="197"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="200"
              x2="15.24"
              y1="200"
              y2="-12.751"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/predict/image-person-one.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
          fill="none"
          viewBox="0 0 200 200"
        >
          <rect
            width="197"
            height="197"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="200"
              x2="15.24"
              y1="200"
              y2="-12.751"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/predict/image-person-two.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
      <ImageThree $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
          fill="none"
          viewBox="0 0 200 200"
        >
          <rect
            width="197"
            height="197"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="200"
              x2="15.24"
              y1="200"
              y2="-12.751"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/predict/image-person-three.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageThree>
      <ImageTextOne $active={selected}>
        <Image
          src="/assets/platform/predict/image-text-one.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTextOne>
      <ImageTextTwo $active={selected}>
        <Image
          src="/assets/platform/predict/image-text-two.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTextTwo>
      <ImageTextThree $active={selected}>
        <Image
          src="/assets/platform/predict/image-text-three.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTextThree>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="359"
          height="368"
          fill="none"
          viewBox="0 0 359 368"
        >
          <rect width="355" height="364" x="2" y="2" stroke="url(#a)" strokeWidth="3" rx="32" />
          <defs>
            <linearGradient
              id="a"
              x1="2"
              x2="433.724"
              y1="2"
              y2="183.331"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
    </WrapperImage>
  );
};

export default ImagePredict;
