import styled from 'styled-components';
import { useRouter } from 'next/router';

import { LinkButton } from '@/components/core/Button';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

const Card = ({ title, description, link, linkLabel }) => {
  const router = useRouter();

  return (
    <Wrapper>
      {title && <TitleCard data-testid="card-title">{title}</TitleCard>}
      {description && <DescriptionCard data-testid="card-desc">{description}</DescriptionCard>}
      <LinkButton
        $primary
        data-testid="card-link"
        href={link}
        onClick={() =>
          trackButtonClicked({
            action: ACTIONS.NAVIGATION,
            buttonLocation: title,
            buttonType: BUTTON_TYPES.NAVIGATION,
            buttonText: linkLabel,
            destination: link,
            url: router.pathname,
          })
        }
      >
        {linkLabel}
      </LinkButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  max-width: 298px;
  background-color: #efe9e6;
  padding: 40px 32px;
  border-radius: 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    padding: 40px 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: unset;
    width: 100%;
    padding: 24px;
  }
`;

const TitleCard = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

const DescriptionCard = styled.div`
  font-family: ${(props) => props.theme.typography.family.body};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default Card;
