import styled from 'styled-components';
import { Container } from '@/lib/flexbox';
import { UpperText, H2 } from '@/components/core/Title';
import { Section } from '@/components/core';

const Wrapper = styled(Section)`
  padding-bottom: 216px;

  ${Container} {
    position: relative;
  }

  & ${UpperText} {
    text-align: center;
  }

  & ${H2} {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 55px;
    line-height: 64px;
    max-width: 1050px;
    padding-bottom: 0;

    b,
    strong {
      color: ${(props) => props.theme.colors.primary.default};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: 45px;
      line-height: 56px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 25px;
      line-height: 32px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 140px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 96px;
  }
`;

export default Wrapper;
