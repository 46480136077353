import { Title } from '@/components/core';
import { memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Title)`
  margin-left: 40px;
  margin-right: 40px;
  display: inline-grid;
  grid-template-columns: auto auto;
  justify-items: center;
  user-select: none;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    align-items: center;
    grid-template-columns: 1fr;
    font-size: 25px;
    line-height: 32px;
    padding-top: 32px;
  }
`;

const Prefix = styled.span`
  transform: translateY(100%);
  transition:
    transform 300ms ease,
    opacity 300ms ease;

  .animate .swiper-slide-active & {
    transform: none;
    transition-delay: 300ms;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

const Label = styled.span`
  color: ${(props) => props.theme.colors.grey.dark}20;
  left: 50%;
  position: relative;
  transform: translateX(-50%) scale(0.8);
  transition:
    color 300ms ease,
    transform 300ms ease;

  .swiper-slide-active & {
    left: 0;
    transform: translateX(0) scale(1);
  }

  .animate .swiper-slide-active & {
    color: ${(props) => props.theme.colors.primary.default};
  }
`;

const Card = ({ label, prefix }) => {
  return (
    <Wrapper size="2">
      <Prefix>{prefix}&nbsp;</Prefix>
      <Label>{label}</Label>
    </Wrapper>
  );
};

export default memo(Card);
