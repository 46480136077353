import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 160px;
  padding-top: 152px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.neutral.default};
    z-index: -2;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

export default Wrapper;
