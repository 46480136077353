import styled, { css } from 'styled-components';

export const InfoWrapper = styled.div`
  position: relative;
  margin-top: 104px;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: flex-start;
    margin-left: -45px;
    margin-top: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: unset;
    margin-top: 40px;
  }

  ${(props) =>
    props.$active &&
    css`
      display: flex;
    `}
`;
