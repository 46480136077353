import styled from 'styled-components';

const Title = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 24px;
  }
`;

export default Title;
