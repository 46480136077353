import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { Heading } from '@swordhealth/ui-corporate';
import { UpperText, Paragraph } from '@/components/core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 406px;
  padding-top: 160px;
  padding-bottom: 220px;

  &:first-child {
    padding-top: 200px;
  }

  .label {
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    padding-top: 0 !important;
    padding-bottom: 0;
    margin-right: 13px;
  }
`;

const SolutionText = ({ index, select, content, isDesktop }) => {
  const [ref, inView] = useInView({
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0,
  });

  useEffect(() => {
    if (inView && index !== undefined && isDesktop) {
      select(index);
    }
  }, [inView]);

  const { title, description, label } = content;
  return (
    <Wrapper ref={ref}>
      {label && (
        <UpperText as="p" className="label">
          {label}
        </UpperText>
      )}
      <Heading as={index === 0 ? 'h3' : 'h4'} size={index === 0 ? 'lg' : 'md'}>
        {title}
      </Heading>
      <Paragraph as="p">{description}</Paragraph>
    </Wrapper>
  );
};

export default SolutionText;
