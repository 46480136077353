import styled from 'styled-components';

import { UpperText, H2, Label } from '@/components/core/Title';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};

  & ${UpperText} {
    text-align: center;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      text-align: left;
    }
  }

  & ${H2} {
    text-align: center;
    margin: 0 auto;
    max-width: 833px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 535px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      text-align: left;
      margin: unset;
    }
  }

  & ${Label} {
    text-align: center;
    margin: 0 auto;
    max-width: 523px;
    padding-bottom: 88px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      text-align: left;
      margin: unset;
    }
  }
`;

export default Wrapper;
