import Image from 'next/image';

import IconColor from '@/public/assets/platform/move/icon.svg';
import { Icon, ImageOne, ImageProduct, ImageTwo, Line, WrapperImage } from './styles';

const ImageMove = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="334"
          height="264"
          fill="none"
          viewBox="0 0 334 264"
        >
          <rect
            width="331"
            height="261"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="334"
              x2="104.149"
              y1="264"
              y2="-70.852"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/move/image-person.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="158"
          height="95"
          fill="none"
          viewBox="0 0 158 95"
        >
          <path stroke="url(#a)" strokeWidth="3" d="M158 2H34C16.327 2 2 16.327 2 34v61" />
          <defs>
            <linearGradient
              id="a"
              x1="158"
              x2="11.349"
              y1="2"
              y2="107.941"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="456"
          height="219"
          fill="none"
          viewBox="0 0 456 219"
        >
          <rect
            width="453"
            height="216"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="456"
              x2="310.814"
              y1="219"
              y2="-129.103"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/move/image-move.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
      <ImageProduct>
        <Image
          src="/assets/platform/move/image-app.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageProduct>
    </WrapperImage>
  );
};

export default ImageMove;
