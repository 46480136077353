import { CircleFilled, CircleOutlined } from '@/components/core/Circle';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

const GraphicElements = () => {
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_sm}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <>
      {isDesktop && (
        <>
          <CircleFilled
            style={{ position: 'absolute', top: '-40px', left: '-174px', zIndex: '-1' }}
            size="260px"
            color={theme.colors.secondary.light}
          />
          <CircleFilled
            style={{ position: 'absolute', bottom: '5px', right: '0', zIndex: '-1' }}
            size="68px"
            color={theme.colors.primary.light}
          />
          <CircleOutlined
            style={{ position: 'absolute', top: '150px', right: '100px', zIndex: '-1' }}
            size="40px"
            color="white"
          />
        </>
      )}

      {isTablet && (
        <>
          <CircleFilled
            style={{ position: 'absolute', top: '-10px', left: '-138px', zIndex: '-1' }}
            size="214px"
            color={theme.colors.secondary.light}
          />
          <CircleFilled
            style={{ position: 'absolute', bottom: '100px', right: '-45px', zIndex: '-1' }}
            size="68px"
            color={theme.colors.primary.light}
          />
          <CircleOutlined
            style={{ position: 'absolute', top: '60px', right: '36px', zIndex: '-1' }}
            size="40px"
            color="white"
          />
        </>
      )}

      {isMobile && (
        <>
          <CircleFilled
            style={{ position: 'absolute', top: '270px', left: '-100px', zIndex: '-1' }}
            size="162px"
            color={theme.colors.secondary.light}
          />
          <CircleFilled
            style={{ position: 'absolute', top: '120px', right: '-35px', zIndex: '-1' }}
            size="68px"
            color={theme.colors.primary.light}
          />
        </>
      )}
    </>
  );
};

export default GraphicElements;
