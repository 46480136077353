import { Section } from '@/components/core';

import LookingForSlider from './LookingForSlider';
import Title from './Title';

const LookingFor = ({ content }) => {
  const { title, slider, buttonLabel } = content;

  return (
    <Section spacerTop>
      <Title>{title}</Title>
      <LookingForSlider content={slider} buttonLabel={buttonLabel} title={title} />
    </Section>
  );
};

export default LookingFor;
