import Image from 'next/image';

import IconColor from '@/public/assets/platform/academy/icon.svg';
import { Icon, ImageOne, ImageTwo, Line, WrapperImage } from './styles';

const ImageAcademy = ({ selected }) => {
  return (
    <WrapperImage $active={selected}>
      <ImageOne $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="261"
          height="533"
          fill="none"
          viewBox="0 0 261 533"
        >
          <rect
            width="258"
            height="530"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="261"
              x2="-164.521"
              y1="533"
              y2="293.062"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/academy/image-app.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageOne>
      <Icon>
        <IconColor />
      </Icon>
      <Line $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="185"
          height="186"
          fill="none"
          viewBox="0 0 185 186"
        >
          <path stroke="url(#a)" strokeWidth="3" d="M185 2H34C16.327 2 2 16.327 2 34v152" />
          <defs>
            <linearGradient
              id="a"
              x1="2"
              x2="223.225"
              y1="2"
              y2="96.756"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
      </Line>
      <ImageTwo $active={selected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="301"
          height="246"
          fill="none"
          viewBox="0 0 301 246"
        >
          <rect
            width="298"
            height="243"
            x="1.5"
            y="1.5"
            stroke="url(#a)"
            strokeWidth="3"
            rx="30.5"
          />
          <defs>
            <linearGradient
              id="a"
              x1="301"
              x2="84.339"
              y1="246"
              y2="-59.264"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8283" />
              <stop offset="1" stopColor="#5993EA" />
            </linearGradient>
          </defs>
        </svg>
        <Image
          src="/assets/platform/academy/image-person.png"
          alt=""
          fill
          sizes="(max-width: 767px)  100vw, 50vw"
        />
      </ImageTwo>
    </WrapperImage>
  );
};

export default ImageAcademy;
