import styled from 'styled-components';

import { Container } from '@/lib/flexbox';

const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: visible;
`;

export default StyledContainer;
