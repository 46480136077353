import styled, { css, keyframes } from 'styled-components';

import { WrapperLogoDefault, Color, NoColor, Label, labelAnimation } from '../../style';

import { timeAnimation } from '../../Platform';

const logoColor = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  54% {
    opacity: 1;
    transform: scale(1.1);
  }
  65% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
const logoNocolor = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  54% {
    opacity: 0;
    transform: scale(1);
  }
  65% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 1132px;
  }
  30% {
    opacity: 1;
    stroke-dashoffset: 1132px;
  }
  60% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  60.1% {
    opacity: 1;
    stroke-dashoffset: 2264px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 1132px;
  }
`;
const lineIcon = keyframes`
  0% {
		stroke-dashoffset: 235px;
  }
  30% {
		stroke-dashoffset: 235px;
  }
  100% {
		stroke-dashoffset: 470px;
  }
`;
const lineTwo = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 1286px;
  }
  10% {
		opacity: 1;
    stroke-dashoffset: 1286px;
  }
	50% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
		opacity: 1;
    stroke-dashoffset: 2572px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 1286px;
  }
`;
const imageAnimation = keyframes`
  0% {
		opacity: 0;
  }
  50% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageTwoAnimation = keyframes`
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
export const Wrapper = styled.div`
  ${WrapperLogoDefault};
  top: 53.9%;
  left: 81.1%;

  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    ${Color} {
      opacity: 1;
      transform: scale(1.1);
    }
    ${NoColor} {
      opacity: 0;
      transform: scale(1.1);
    }
    ${Label} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
  }
`;

export const AnimationColor = styled(Color)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoColor} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const AnimationNoColor = styled(NoColor)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoNocolor} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const AnimationLabel = styled(Label)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${labelAnimation} ${timeAnimation}s ${props.$delay}s ease-in-out;
    `};
`;
export const WrapperImage = styled.div`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
export const ImageOne = styled.figure`
  position: absolute;
  top: 0;
  left: 33%;
  width: 55%;
  padding-bottom: 43%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 92%;
    position: absolute;
    top: 2%;
    left: 4%;
    height: 92%;
    z-index: 2;
    stroke-dasharray: 1132px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${line} 2.5s ease-in-out;
      }
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 90%;
      top: 3%;
      left: 5%;
      height: 90%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 92%;
      top: 2%;
      left: 4%;
      height: 92%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 90%;
      top: 3%;
      left: 5.1%;
      height: 90%;
    }
  }
`;
export const Line = styled.div`
  position: absolute;
  z-index: 0;
  width: 23%;
  top: 23%;
  left: 14%;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke-dasharray: 235px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${lineIcon} 2.5s ease-in-out;
        }
      }
    `};
`;
export const ImageTwo = styled.figure`
  position: absolute;
  top: 26%;
  left: 7%;
  width: 71%;
  padding-bottom: 53%;
  z-index: 2;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 95.5%;
    position: absolute;
    top: 0.8%;
    left: 2%;
    height: 96%;
    z-index: 2;
    stroke-dasharray: 1286px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineTwo} 2s ease-in-out;
      }
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 94%;
      top: 1%;
      left: 3%;
      height: 94.5%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 95.5%;
      top: 0.8%;
      left: 2%;
      height: 96%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 93.5%;
      top: 1%;
      left: 3%;
      height: 95%;
    }
  }
`;
export const Icon = styled.div`
  position: absolute;
  z-index: 1;
  top: 17%;
  left: 19.2%;
  width: 12%;
  height: 12%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
    top: 16%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
    top: 17%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
    top: 15%;
  }
`;
export const ImageProduct = styled.figure`
  position: absolute;
  top: 40.5%;
  left: 67%;
  width: 28%;
  padding-bottom: 42%;
  z-index: 2;

  img {
    object-fit: contain;
  }
`;
