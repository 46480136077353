import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 768px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 568px;
  }
`;

export default Wrapper;
