import Link from 'next/link';
import IconColor from '@/public/assets/platform/academy/icon.svg';
import { Wrapper, AnimationColor, AnimationNoColor, AnimationLabel } from './styles';

const SvgAcademy = ({ active, delay }) => {
  return (
    <Wrapper>
      <Link href="/solutions/academy">
        <AnimationColor $active={active} $delay={delay}>
          <IconColor />
        </AnimationColor>
        <AnimationNoColor $active={active} $delay={delay}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="88"
            fill="none"
            viewBox="0 0 88 88"
          >
            <rect width="86" height="86" x="1" y="1" fill="#F7F4F2" rx="43" />
            <rect width="86" height="86" x="1" y="1" stroke="#E6DCD4" strokeWidth="2" rx="43" />
            <path
              fill="#E6DCD4"
              d="M41 54.775c5.727 0 10.413-4.682 10.43-10.414.018-5.732-4.596-10.39-10.353-10.36L41 54.768v.006Z"
            />
            <path
              fill="#E6DCD4"
              d="M66.182 34.8c-1.173-2.922-2.956-5.56-5.183-7.788a24.043 24.043 0 0 0-7.783-5.186c-2.878-1.216-6-1.826-9.163-1.826a23.9 23.9 0 0 0-13.326 4.06h-.006c-3.933 2.632-7.055 6.366-8.873 10.746a23.813 23.813 0 0 0-1.386 13.876 23.942 23.942 0 0 0 6.563 12.287 23.955 23.955 0 0 0 12.278 6.567c4.656.937 9.483.445 13.86-1.38a23.917 23.917 0 0 0 10.78-8.844A24.08 24.08 0 0 0 68 43.964c0-3.124-.61-6.248-1.824-9.17l.006.006ZM56.095 56.132a17.157 17.157 0 0 1-12.142 5.05c-3.406 0-6.729-.996-9.548-2.91-2.82-1.873-5.005-4.605-6.326-7.788-1.268-3.136-1.635-6.597-.954-9.964a17.133 17.133 0 0 1 4.68-8.832c2.369-2.406 5.46-4.048 8.777-4.73 3.317-.628 6.776-.314 9.916 1.008a17.12 17.12 0 0 1 7.73 6.372c1.906 2.815 2.907 6.188 2.907 9.596 0 4.546-1.818 8.962-5.046 12.192l.006.006Z"
            />
          </svg>
        </AnimationNoColor>
        <AnimationLabel $active={active} $delay={delay}>
          Academy
        </AnimationLabel>
      </Link>
    </Wrapper>
  );
};

export default SvgAcademy;
