import styled from 'styled-components';

import { Label, H3 } from '@/components/core/Title';
import { LinkButton } from '@/components/core/Button';

const InfoData = ({ title, subTitle, link, linkLabel }) => {
  return (
    <Wrapper>
      <H3>{title}</H3>
      <Label>{subTitle}</Label>
      {link && <LinkButton href={link}>{linkLabel}</LinkButton>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 406px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 130px;

  ${H3} {
    text-align: left;
    padding-bottom: 16px;
  }

  ${Label} {
    padding-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
    margin-left: 52px;

    ${H3} {
      font-size: 25px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 0;
    margin-top: 60px;
    max-width: 100%;

    ${H3} {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 16px;
    }
  }

  ${LinkButton} {
    width: fit-content;
  }
`;

export default InfoData;
