import { Section } from '@/components/core';
import { Container } from '@/lib/flexbox';
import Intro from '../Intro';
import TestimonialsSlider from './TestimonialsSlider';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0;
  }
`;

const Testimonials = ({ content, spacerTop = true }) => {
  if (!content) return null;

  const { sectionTitle, title, description, quotes } = content;

  return (
    <Section spacerTop={spacerTop}>
      <Intro
        title={title}
        description={description}
        upperLabel={sectionTitle}
        as="header"
        innerSpacer
      />
      <StyledContainer>
        <TestimonialsSlider quotes={quotes} />
      </StyledContainer>
    </Section>
  );
};

export default Testimonials;
