import styled, { css, keyframes } from 'styled-components';

import { WrapperLogoDefault, Color, NoColor, Label, labelAnimation } from '../../style';

import { timeAnimation } from '../../Platform';

const logoColor = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  54% {
    opacity: 1;
    transform: scale(1.1);
  }
  65% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;
const logoNocolor = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  10% {
    opacity: 0;
    transform: scale(1.1);
  }
  54% {
    opacity: 0;
    transform: scale(1);
  }
  65% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 1524px;
  }
  10% {
    opacity: 1;
    stroke-dashoffset: 1524px;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
    opacity: 1;
    stroke-dashoffset: 3048px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 1524px;
  }
`;
const lineIcon = keyframes`
  0% {
    stroke-dashoffset: 315px;
  }
  30% {
    stroke-dashoffset: 315px;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;
const lineTwo = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 1080px;
  }
  20% {
		opacity: 1;
    stroke-dashoffset: 1080px;
  }
	50% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
		opacity: 1;
    stroke-dashoffset: 2160px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 1080px;
  }
`;
const imageAnimation = keyframes`
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageTwoAnimation = keyframes`
  0% {
		opacity: 0;
  }
  50% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
export const Wrapper = styled.div`
  ${WrapperLogoDefault};
  top: 71%;
  left: 64%;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${Color} {
      opacity: 1;
      transform: scale(1.1);
    }
    ${NoColor} {
      opacity: 0;
      transform: scale(1.1);
    }
    ${Label} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
  }
`;

export const AnimationColor = styled(Color)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoColor} ${timeAnimation}s 1s ease-in-out;
    `};
`;
export const AnimationNoColor = styled(NoColor)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${logoNocolor} ${timeAnimation}s 1s ease-in-out;
    `};
`;
export const AnimationLabel = styled(Label)`
  ${(props) =>
    props.$active &&
    css`
      animation: ${labelAnimation} ${timeAnimation}s 1s ease-in-out;
    `};
`;
export const WrapperImage = styled.div`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
export const ImageOne = styled.figure`
  position: absolute;
  top: 0;
  left: 0%;
  width: 57%;
  padding-bottom: 82%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 98%;
    position: absolute;
    top: 1.5%;
    left: 1.2%;
    height: 95%;
    z-index: 2;
    stroke-dasharray: 1524px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${line} 2s ease-in-out;
      }
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 94%;
      top: 2%;
      left: 3%;
      height: 94%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 98%;
      top: 1.5%;
      left: 1.2%;
      height: 95%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 94%;
      top: 2%;
      left: 3%;
      height: 94%;
    }
  }
`;
export const ImageTwo = styled.figure`
  position: absolute;
  top: 29.5%;
  left: 26%;
  width: 80%;
  padding-bottom: 42%;
  z-index: 0;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 91%;
    position: absolute;
    top: 3%;
    left: 5%;
    height: 90%;
    z-index: 2;
    stroke-dasharray: 1080px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineTwo} 2.5s ease-in-out;
      }
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    left: 25.5%;
    svg {
      width: 89%;
      top: 4%;
      left: 6%;
      height: 89%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 91%;
      top: 3%;
      left: 5%;
      height: 90%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 94%;
      top: 4%;
      left: 3%;
      height: 88%;
    }
  }
`;
export const Line = styled.div`
  position: absolute;
  z-index: 0;
  width: 30%;
  top: 18%;
  left: 45.5%;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke-dasharray: 315px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${lineIcon} 2.5s ease-in-out;
        }
      }
    `};
`;
export const Icon = styled.div`
  position: absolute;
  z-index: 1;
  top: 12%;
  left: 56%;
  width: 12%;
  height: 12%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
    top: 11%;
    left: 54%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
    top: 12%;
    left: 56%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
    top: 10%;
    left: 53%;
  }
`;
export const ImageProduct = styled.figure`
  position: absolute;
  top: 40.5%;
  left: 67%;
  width: 28%;
  padding-bottom: 42%;
  z-index: 2;

  img {
    object-fit: contain;
  }
`;
