import styled from 'styled-components';
import { UpperText, H2 } from '@/components/core/Title';

export const Wrapper = styled.div`
  & ${UpperText} {
    text-align: center;
  }

  & ${H2} {
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    & ${H2} {
      max-width: 536px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    & ${H2} {
      max-width: 100%;
      text-align: left;
    }

    & ${UpperText} {
      text-align: left;
    }
  }
`;
