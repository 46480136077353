import styled, { css, keyframes } from 'styled-components';
import { timeAnimation } from '../../Platform';

const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 706px;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 1412px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 2066px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 20%;
  right: 13%;
  z-index: 0;
  width: 59%;

  svg {
    width: 100%;
    height: 100%;

    path {
      opacity: 0;
      stroke-dasharray: 706px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${line} ${timeAnimation}s ease-in-out;
        }
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    top: 20.5%;
    right: 12%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    top: 21%;
    right: 11%;
    width: 60%;
  }
`;

const LineFour = ({ active }) => {
  return (
    <Wrapper $active={active}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="426"
        height="298"
        fill="none"
        viewBox="0 0 426 298"
      >
        <path stroke="url(#a)" strokeWidth="3" d="M0 2h392c17.673 0 32 14.327 32 32v264" />
        <defs>
          <linearGradient
            id="a"
            x1="0"
            x2="439.387"
            y1="2"
            y2="273.057"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".25" stopColor="#5993EA" />
            <stop offset="1" stopColor="#FF8283" />
          </linearGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};

export default LineFour;
