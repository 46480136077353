import { useState } from 'react';

import { Wrapper } from './Wrapper';
import Header from './Header';
import Nav from './Nav';
import Info from './Info';

import { Container } from '@/lib/flexbox';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import { InfoWrapper } from '@/components/homepage/OurDna/Info/InfoWrapper';

const OurDna = ({ content }) => {
  const [active, setActive] = useState(0);

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_md}px)`);

  const { label, title, navigation, info } = content;

  const handleClick = (index) => {
    if (active !== index) {
      setActive(index);
    }
  };

  return (
    <Wrapper>
      {!isMobile ? (
        <Container>
          <Header label={label} title={title} />
          <Nav active={active} handleClick={handleClick} data={navigation} />
          {info?.map((info, index) => (
            <InfoWrapper key={info.id} $active={active === index} data-testid={`info-${index}`}>
              <Info data={info} position={index} />
            </InfoWrapper>
          ))}
        </Container>
      ) : (
        <>
          <Container>
            <Header label={label} title={title} />
          </Container>
          <Nav active={active} handleClick={handleClick} data={navigation} />
          <Container>
            {info?.map((info, index) => (
              <InfoWrapper key={info.id} $active={active === index} data-testid={`info-${index}`}>
                <Info data={info} position={index} />
              </InfoWrapper>
            ))}
          </Container>
        </>
      )}
    </Wrapper>
  );
};

export default OurDna;
