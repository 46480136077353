import styled, { css, keyframes } from 'styled-components';

import { WrapperLogoDefault, Color, NoColor, Label } from '../../style';

const line = keyframes`
  0% {
    opacity: 1;
    stroke-dashoffset: 1224px;
  }
  30% {
    opacity: 1;
    stroke-dashoffset: 1224px;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 0px;
  }
  50.1% {
    opacity: 1;
    stroke-dashoffset: 2448px;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 1224px;
  }
`;
const lineIcon = keyframes`
  0% {
		stroke-dashoffset: 303px;
  }
  40% {
		stroke-dashoffset: 303px;
  }
  100% {
		stroke-dashoffset: 0px;
  }
`;
const lineTwo = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 1122px;
  }
	40% {
		opacity: 1;
    stroke-dashoffset: 1122px;
  }
  70% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  70.1% {
		opacity: 1;
    stroke-dashoffset: 2244px;
  }
  100% {
		opacity: 1;
    stroke-dashoffset: 1122px;
  }
`;
const lineThree = keyframes`
  0% {
		opacity: 1;
    stroke-dashoffset: 1234px;
  }
  20% {
		opacity: 1;
    stroke-dashoffset: 1234px;
  }
  50% {
		opacity: 1;
    stroke-dashoffset: 0;
  }
  50.1% {
		opacity: 1;
    stroke-dashoffset: 2468px;
  }
	100% {
		opacity: 1;
    stroke-dashoffset: 1234px;
  }
`;

const imageAnimation = keyframes`
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageTwoAnimation = keyframes`
  0% {
		opacity: 0;
  }
  50% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
const imageThreeAnimation = keyframes`
  0% {
		opacity: 0;
  }
  70% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
`;
export const Wrapper = styled.div`
  ${WrapperLogoDefault};
  top: 54%;
  left: 47%;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${Color} {
      opacity: 1;
      transform: scale(1.1);
    }
    ${NoColor} {
      opacity: 0;
      transform: scale(1.1);
    }
    ${Label} {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
  }
`;
export const WrapperImage = styled.div`
  opacity: 0;
  transition: opacity 120ms ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
export const ImageOne = styled.figure`
  position: absolute;
  top: 0;
  left: 31.5%;
  width: 36%;
  padding-bottom: 68%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 96%;
    position: absolute;
    top: 1.5%;
    left: 2.1%;
    height: 94%;
    z-index: 2;
    stroke-dasharray: 1224px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${line} 2s ease-in-out;
      }
      img {
        animation: ${imageTwoAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 95%;
      top: 2%;
      left: 2.3%;
      height: 93%;
      z-index: 2;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 97.5%;
      top: 1.8%;
      left: 1.2%;
      height: 94%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 94%;
      top: 2.5%;
      left: 3%;
      height: 92.5%;
    }
  }
`;
export const ImageTwo = styled.figure`
  position: absolute;
  top: 46.5%;
  left: 5.5%;
  width: 54%;
  padding-bottom: 43%;
  z-index: 0;

  img {
    object-fit: contain;
    z-index: 1;
    padding: 3px;
  }
  svg {
    width: 93%;
    position: absolute;
    top: 2%;
    left: 3.5%;
    height: 92%;
    z-index: 2;
    stroke-dasharray: 1122px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineTwo} 2.5s ease-in-out;
      }
      img {
        animation: ${imageThreeAnimation} 1s ease-in-out;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 92%;
      top: 2%;
      left: 3.5%;
      height: 92%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 93%;
      top: 2%;
      left: 3.5%;
      height: 92%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 94%;
      top: 4%;
      left: 3%;
      height: 88%;
    }
  }
`;
export const ImageThree = styled.figure`
  position: absolute;
  top: 7.5%;
  left: 58%;
  width: 36%;
  padding-bottom: 68%;
  overflow: clip;

  img {
    object-fit: contain;
    z-index: 2;
    padding: 3px;
  }
  svg {
    width: 98%;
    position: absolute;
    top: 1.5%;
    left: 1.2%;
    height: 94.5%;
    z-index: 3;
    stroke-dasharray: 1234px;
    opacity: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        animation: ${lineThree} 2.3s ease-in-out;
      }
      img {
        animation: ${imageAnimation} 1s ease-in-out;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 97%;
      top: 2%;
      left: 1.2%;
      height: 93.5%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    svg {
      width: 98%;
      top: 1.8%;
      left: 1.2%;
      height: 94%;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    svg {
      width: 94%;
      top: 2.5%;
      left: 3%;
      height: 92.5%;
    }
  }
`;
export const Line = styled.div`
  position: absolute;
  z-index: 0;
  width: 15%;
  top: 17.5%;
  left: 20.5%;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke-dasharray: 303px;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      svg {
        path {
          animation: ${lineIcon} 2.5s ease-in-out;
        }
      }
    `};
`;
export const Icon = styled.div`
  position: absolute;
  z-index: 1;
  top: 27%;
  left: 15%;
  width: 12%;
  height: 12%;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 14%;
    height: 14%;
    top: 27%;
    left: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 13%;
    height: 13%;
    top: 27%;
    left: 14%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 15%;
    height: 15%;
    top: 27%;
    left: 13%;
  }
`;
export const ImageProduct = styled.figure`
  position: absolute;
  top: 40.5%;
  left: 67%;
  width: 28%;
  padding-bottom: 42%;
  z-index: 2;

  img {
    object-fit: contain;
  }
`;
