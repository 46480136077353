import styled from 'styled-components';

export const Title = styled.h1`
  max-width: 615px;
  font-weight: 800;
  font-size: 76px;
  line-height: 80px;
  color: ${(props) => props.theme.colors.white};
  margin-top: 20px;
  margin-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 270px;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

export default Title;
