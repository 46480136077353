import { useState } from 'react';

import SolutionText from '@/components/homepage/OurSolutions/SolutionText';
import StickyPanel from '@/components/homepage/OurSolutions/StickyPanel';
import MobileRow from '@/components/homepage/OurSolutions/MobileRow';
import TextCol from '@/components/homepage/OurSolutions/TextCol';
import Solution from '@/components/homepage/OurSolutions/Solution';
import { Col, Row } from '@/lib/flexbox';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

export default function OurSolutionsInner({ content }) {
  const [selected, setSelected] = useState(0);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  return isDesktop ? (
    <Row>
      <Col xs={5}>
        {content.map((solution, index) => (
          <SolutionText
            key={solution.id}
            index={index}
            select={setSelected}
            content={solution}
            isDesktop={isDesktop}
          />
        ))}
      </Col>
      <Col xs={7}>
        <StickyPanel selected={selected} content={content} isDesktop={isDesktop} />
      </Col>
    </Row>
  ) : (
    content.map((solution, index) => (
      <MobileRow key={solution.id}>
        <TextCol md={6} xs={12}>
          <SolutionText
            select={setSelected}
            content={solution}
            index={index}
            isDesktop={isDesktop}
          />
        </TextCol>
        <Col md={6} xs={12}>
          <Solution selected={selected} content={solution} index={index} />
        </Col>
      </MobileRow>
    ))
  );
}
