import styled from 'styled-components';
import Image from 'next/image';

import LogoWrapper from './LogoWrapper';

import getImgSrcWorkaround from '@/utils/images';

import OpenQuotes from '@/public/assets/icons/quotes.svg';

const Quote = ({ quote, customerName, position, logo }) => {
  if (!quote) return null;

  return (
    <Wrapper>
      <OpenQuotes />
      <QuoteText data-testid="quote-info">{quote}</QuoteText>
      {customerName && <Name data-testid="quote-name">{customerName}</Name>}
      {position && <Company data-testid="quote-position">{position}</Company>}
      {logo && (
        <LogoWrapper>
          <Image
            data-testid="quote-logo"
            src={getImgSrcWorkaround(logo.data.attributes.url)}
            alt={logo.data.attributes.alternativeText || ''}
            fill
            sizes="118px"
          />
        </LogoWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary.light};
  padding: 72px;
  margin-right: 12px;
  border-radius: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px 24px 40px 24px;
  }
`;

const QuoteText = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  margin-top: 24px;

  b {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    line-height: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    max-width: 296px;

    b {
      margin-bottom: 16px;
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    font-size: 25px;
    line-height: 32px;
  }
`;

const Name = styled.div`
  font-family: ${(props) => props.theme.typography.family.body};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
`;

const Company = styled.div`
  font-family: ${(props) => props.theme.typography.family.body};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export default Quote;
