import styled from 'styled-components';
import { Container } from '@/lib/flexbox';

const Content = styled(Container)`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    align-items: flex-end;
    padding-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 24px;
  }
`;

export default Content;
